import i18next from "i18next";
import {useBaseObjectForm} from "../../customHooks/hooks";
import {useState} from "react";
import moment from "moment";
import {createMySpendingForTripSheet} from "../../backend/axios";
import {getErrorMessage, getResponseErrors} from "../../helpers/helpers";
import {Button, Divider, Grid, LoadingOverlay, Select, TextInput} from "@mantine/core";
import {DatePickerInput} from "@mantine/dates";
import countryOptions from "../../backend/country_list.json";
import {useAppSettings} from "../../providers/AppProvider";

const SpendingModal = ({id = undefined, onSave = undefined}: any) => {
    const {t}: any = i18next
    const {data, onDataChange, setErrors, errors} = useBaseObjectForm({
        receipt_date: new Date(),
        receipt_number: '',
        receipt_amount: '',
        fuel_amount: '',
        receipt_currency: '€',
        payment_type: '',
        country: 'LT',
        receipt_supplier: '',
        receipt_type: ''
    })

    const {appSettings} = useAppSettings()
    const receiptTypes = appSettings.receipt_types.map(type => {return {label: t(type), value: type}})
    const receiptSuppliers = appSettings.receipt_suppliers.map(type => {return {label: t(type), value: type}})

    const [loading, setLoading] = useState(false)
    const onSubmit = (e) => {
        if (e) e.preventDefault()
        setLoading(true)

        let _receipt_date = ''
        if (data.receipt_date) _receipt_date = moment(data.receipt_date).format('YYYY-MM-DD')

        if (id) createMySpendingForTripSheet(id, {...data, receipt_date: _receipt_date}).then(r => {
            if (r && r.data && onSave) onSave(r.data)
            setLoading(false)
        }).catch(e => {
            setErrors(getResponseErrors(e))
            setLoading(false)
        })
    }

    return <>
        <LoadingOverlay visible={loading}/>
        <form onSubmit={onSubmit}>
            <DatePickerInput label={t('receipt_date')}
                             valueFormat={'YYYY-MM-DD'}
                             defaultLevel={'year'}
                             firstDayOfWeek={1}
                             value={data.receipt_date || null}
                             error={getErrorMessage(errors, 'receipt_date')}
                             onChange={(dt) => onDataChange({receipt_date: dt})}/>

            <Select label={t('country')}
                    data={countryOptions}
                    searchable
                    withinPortal
                    value={data.country}
                    error={getErrorMessage(errors, 'country')}
                    onChange={(itm) => onDataChange({country: itm})}/>

            <Divider mt={15} mb={5}/>

            <Select label={t('receipt_supplier')}
                    data={receiptSuppliers}
                    searchable
                    withinPortal
                    value={data.receipt_supplier}
                    error={getErrorMessage(errors, 'receipt_supplier')}
                    onChange={(itm) => onDataChange({receipt_supplier: itm})}/>

            <Select label={t('receipt_type')}
                    data={receiptTypes}
                    searchable
                    withinPortal
                    value={data.receipt_type}
                    error={getErrorMessage(errors, 'receipt_type')}
                    onChange={(itm) => onDataChange({receipt_type: itm})}/>

            <TextInput label={t('receipt_number')}
                       value={data.receipt_number}
                       error={getErrorMessage(errors, 'receipt_number')}
                       onChange={(e) => onDataChange({receipt_number: e.target.value})}/>

            <Select label={t('payment_type')}
                    data={[
                        {label: t('payment_type_cash'), value: 'cash'},
                        {label: t('payment_type_card'), value: 'card'}
                    ]}
                    searchable
                    withinPortal
                    value={data.payment_type}
                    error={getErrorMessage(errors, 'payment_type')}
                    onChange={(itm) => onDataChange({payment_type: itm})}/>


            <Grid gutter={0} align={"baseline"}>
                <Grid.Col lg={9} md={9} sm={9} xs={9}>
                    <TextInput label={t('receipt_amount')}
                               value={data.receipt_amount}
                               error={getErrorMessage(errors, 'receipt_amount')}
                               onChange={(e) => onDataChange({receipt_amount: e.target.value})}/>
                </Grid.Col>

                <Grid.Col lg={3} md={3} sm={3} xs={3}>
                    <Select label={t('receipt_currency')}
                            data={['€', '$', '£', '₽']}
                            searchable
                            withinPortal
                            value={data.receipt_currency}
                            error={getErrorMessage(errors, 'receipt_currency')}
                            onChange={(itm) => onDataChange({receipt_currency: itm})}/>
                </Grid.Col>
            </Grid>

            <TextInput label={t('amount')}
                       value={data.amount}
                       error={getErrorMessage(errors, 'amount')}
                       onChange={(e) => onDataChange({amount: e.target.value})}/>

            <Button compact mt={20} type={"submit"}>{t('save')}</Button>
        </form>

    </>
}
export default SpendingModal

import i18next from "i18next";
import {useAppSettings} from "../providers/AppProvider";
import {useBaseObjectForm} from "../customHooks/hooks";
import {useState} from "react";
import moment from "moment";
import {storeCar, updateCar} from "../backend/axios";
import {showNotification} from "@mantine/notifications";
import {closeModal} from "@mantine/modals";
import {getErrorMessage, getResponseErrors} from "../helpers/helpers";
import {Button, Checkbox, Divider, LoadingOverlay, Select, TextInput} from "@mantine/core";
import {DatePickerInput} from "@mantine/dates";

const NewCarForm = ({onCreate = undefined, onSave = undefined, isUpdating = false, selected = undefined}: any) => {
    const {t} = i18next

    const {appSettings} = useAppSettings()
    const carTypes = appSettings.car_types.map(type => {return {label: t(type), value: type}}) ?? []

    const {data, onDataChange, setErrors, errors} = useBaseObjectForm({
        manufacture_date: null,
        make: '',
        model: '',
        plate: '',
        tank_capacity: '',
        odometer: '',
        vin: '',
        fuel_norm_summer: '',
        fuel_norm_winter: '',
        tonkilometer: '',
        ad_blue_required: false,
        ...selected
    })

    const [loading, setLoading] = useState(false)
    const onSubmit = (e) => {
        if (e) e.preventDefault()
        setLoading(true)

        let _manufacture_date = ''
        if (data.manufacture_date) _manufacture_date = moment(data.manufacture_date).format('YYYY-MM-DD')

        if (isUpdating) updateCar(selected.id, {...data, manufacture_date: _manufacture_date}).then(r => {
            if (r && r.data && onSave) {
                onSave(r.data, selected.listIndex)
                showNotification({color: 'green', message: t('car_updated') || 'Atnaujintas automobilis'})
                closeModal('carModal')
            }
            setLoading(false)
        }).catch(e => setErrors(getResponseErrors(e, setLoading)))

        else storeCar({...data, manufacture_date: _manufacture_date}).then(r => {
            if (r && r.data && onCreate) {
                showNotification({color: 'green', message: t('car_created') || 'Sukurtas automobilis'})
                onCreate(r.data)
                closeModal('carModal')
            }
            setLoading(false)
        }).catch(e => setErrors(getResponseErrors(e, setLoading)))
    }

    return <>
        <div style={{position: 'relative'}}>
            <LoadingOverlay visible={loading}/>
            <form onSubmit={onSubmit}>
                <Select data={carTypes}
                        value={data.type}
                        label={t('type')}
                        onChange={(itm) => onDataChange({type: itm})}
                        error={getErrorMessage(errors, 'type')}/>

                <TextInput label={t('car_make')}
                           value={data.car_make}
                           error={getErrorMessage(errors, 'car_make')}
                           onChange={(e) => onDataChange({car_make: e.target.value})}/>

                <TextInput label={t('car_model')}
                           value={data.car_model}
                           error={getErrorMessage(errors, 'car_model')}
                           onChange={(e) => onDataChange({car_model: e.target.value})}/>

                <TextInput label={t('plate')}
                           value={data.plate}
                           placeholder={'XXX 000'}
                           error={getErrorMessage(errors, 'plate')}
                           onChange={(e) => onDataChange({plate: e.target.value})}/>

                <DatePickerInput label={t('manufacture_date')}
                                 value={data.manufacture_date ? new Date(data.manufacture_date) : null}
                                 defaultLevel={'year'}
                                 valueFormat={'YYYY-MM-DD'}
                                 firstDayOfWeek={1}
                                 error={getErrorMessage(errors, 'manufacture_date')}
                                 onChange={(dt) => onDataChange({manufacture_date: dt})}/>

                <TextInput label={t('odometer')}
                           value={data.odometer}
                           error={getErrorMessage(errors, 'odometer')}
                           onChange={(e) => onDataChange({odometer: e.target.value})}/>

                <TextInput label={t('vin')}
                           value={data.vin}
                           error={getErrorMessage(errors, 'vin')}
                           onChange={(e) => onDataChange({vin: e.target.value})}/>

                <Divider mt={20} mb={15}/>

                <TextInput label={t('tank_capacity')}
                           value={data.tank_capacity}
                           error={getErrorMessage(errors, 'tank_capacity')}
                           onChange={(e) => onDataChange({tank_capacity: e.target.value})}/>


                <TextInput label={t('fuel_norm_summer') || 'Kuro norma vasarą'}
                           value={data.fuel_norm_summer}
                           error={getErrorMessage(errors, 'fuel_norm_summer')}
                           onChange={(e) => onDataChange({fuel_norm_summer: e.target.value})}/>

                <TextInput label={t('fuel_norm_winter') || 'Kuro norma žiemą'}
                           value={data.fuel_norm_winter}
                           error={getErrorMessage(errors, 'fuel_norm_winter')}
                           onChange={(e) => onDataChange({fuel_norm_winter: e.target.value})}/>

                <TextInput label={t('ton_kilometer')}
                           value={data.tonkilometer}
                           error={getErrorMessage(errors, 'tonkilometer')}
                           onChange={(e) => onDataChange({tonkilometer: e.target.value})}/>

                <Checkbox mt={20} label={t('ad_blue_required')} value={data.ad_blue_required} onChange={() => onDataChange({ad_blue_required: !data.ad_blue_required})} />

                <Button compact mt={20} type={"submit"}>{t('save')}</Button>
            </form>
        </div>
    </>
}
export default NewCarForm

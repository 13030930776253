import {Badge, Button, Grid, LoadingOverlay, Table, TextInput, Title} from "@mantine/core";
import {Search} from "tabler-icons-react";
import {useEffect, useState} from "react";
import {showNotification} from "@mantine/notifications";
import {getAllTripSheets, getMyTripSheets} from "../../backend/axios";
import TableLoadingSkeleton from "../../components/TableLoadingSkeleton";
import {useNavigate} from "react-router-dom";
import i18next from "i18next";
import {globalStyles} from "../../AppRouter";
import {closeModal, openModal} from "@mantine/modals";
import TripSheetShow from "./TripSheetShow";
import {useMediaQuery} from "@mantine/hooks";

const TripSheetList = ({type}) => {
    const {classes: globalClasses} = globalStyles()
    const navigate = useNavigate()
    const {t} = i18next
    const [data, setData] = useState([])
    const [filter, setFilter]: any = useState({
        query: ''
    })
    const [loading, setLoading] = useState(false)
    const isMobile = useMediaQuery("(max-width: 800px)");

    const getData = () => {
        setLoading(true)

        if (type === 'all') getAllTripSheets(filter).then(r => {
            if (r && r.data && r.data) {
                setData(r.data)
            }
            setLoading(false)
        }).catch(() => {
            showNotification({color: 'red', message: t('error') || 'Klaida'})
            setLoading(false)
        })
        else if (type === 'my') getMyTripSheets(filter).then(r => {
            if (r && r.data && r.data) {
                setData(r.data)
            }
            setLoading(false)
        }).catch(() => {
            showNotification({color: 'red', message: t('error') || 'Klaida'})
            setLoading(false)
        })
    }

    useEffect(() => {
        getData()
    }, [type, filter])

    const openTripSheet = (id) => {
        openModal({
            modalId: 'view_tripSheet',
            title: <div style={{display: "flex", alignItems: 'center', gap: 10}}>
                <span>{t('tripSheet')}</span>
                <Button size={'xs'} compact variant={'outline'}
                        onClick={() => {
                            navigate(`/${type == 'my' ? 'my-travels' : 'travels'}/${id}`)
                            closeModal('view_tripSheet')
                        }}>{t('full_view')}</Button>
            </div>,
            centered: true,
            overlayProps: {
                opacity: 0.5,
                blur: 0.2
            },
            size: 'xl',
            fullScreen: isMobile,
            styles: {
                inner: {
                    padding: 0,
                    'div': {
                        maxHeight: isMobile ? '100vh' : ''
                    }
                }
            },
            children: <TripSheetShow itemId={id} isShowing modalViewing/>
        })
    }

    return <>
        <section className={'main'}>
            <div className={'main-content ' + globalClasses.card}>
                <div className={globalClasses.block_header}>
                    <Title order={6}>{type == 'my'
                        ? t('page_my_tripSheets')
                        : t('page_all_tripSheets')}
                    </Title>
                    {
                        type == 'my' && <div style={{display: 'flex', gap: 10}}>
                            <Button compact size={'xs'} variant={'filled'} onClick={() => navigate('/my-travels/create')}>
                                {t('create_new')}
                            </Button>
                        </div>
                    }
                </div>

                <Grid align={'flex-end'} p={0} mt={15}>
                    <Grid.Col lg={8} md={8} sm={12}>
                        <TextInput placeholder={t('search') || ""}
                                   variant={"unstyled"}
                                   styles={{root: {borderBottom: "1px solid #e8e9ec"}}}
                                   icon={<Search size={20}
                                                 strokeWidth={1.2}/>}
                                   value={filter.query}
                                   onChange={(e) => setFilter({...filter, query: e.target.value})}/>

                    </Grid.Col>
                </Grid>
                <div className={globalClasses.table_wrapper}>
                    <LoadingOverlay visible={loading} loaderProps={{size: 'sm'}} zIndex={5}/>
                    <Table fontSize={'xs'} highlightOnHover className={'productListTable'}
                           style={{minWidth: 500}}>
                        <thead>
                        <tr>
                            <th>{t('tripSheet_number')}</th>
                            <th>{t('trip')}</th>
                            <th>{t('driver')}</th>
                            <th>{t('truck')}</th>
                            <th>{t('status')}</th>
                            {/*<th style={{width: 50}}></th>*/}
                        </tr>
                        </thead>

                        <tbody>
                        {loading ? <TableLoadingSkeleton columnCount={5} rowCount={6}/>
                            : data && data.length > 0 && data.map((item: any, index) => <tr key={`user-${index}`}
                                                                                            style={{cursor: 'pointer'}}
                                                                                            onClick={() => openTripSheet(item.id)}>

                                <td>{item.id}</td>
                                <td>{item.trip ? item.trip : '--'}</td>
                                <td>{item.driver && <>{item.driver.first_name && item.driver.first_name} {item.driver.last_name && item.driver.last_name}</>}</td>
                                <td>
                                    {
                                        item.car
                                            ? <div>
                                                <div>{item.car.manufacturer}</div>
                                                <div style={{
                                                    color: '#a4a4a4', fontWeight: 500, letterSpacing: 0.3, fontSize: 11
                                                }}>
                                                    <span>{item.car.plate}</span>
                                                    {item.car.trailer && <span> / {item.car.trailer} /</span>}
                                                </div>
                                            </div>
                                            : ''
                                    }
                                </td>

                                <td>
                                    <Badge color={item.status === 'draft' ? 'gray.8' : 'gray.7'}
                                           variant={item.status === 'draft' ? 'filled' : 'light'}
                                           styles={{root: {fontWeight: 600}}}>
                                        {item.status}
                                    </Badge>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </div>
            </div>
        </section>
    </>
}

export default TripSheetList



import i18next from "i18next";
import {useBaseObjectForm} from "../customHooks/hooks";
import {useState} from "react";
import {storeUser, updateUser} from "../backend/axios";
import {showNotification} from "@mantine/notifications";
import {closeModal} from "@mantine/modals";
import {getErrorMessage, getResponseErrors} from "../helpers/helpers";
import {Button, LoadingOverlay, MultiSelect, PasswordInput, Switch, TextInput} from "@mantine/core";

const UserForm = ({
                      onCreate = undefined,
                      onSave = undefined,
                      isUpdating = false,
                      selected = undefined,
                      roleOptions = []
                  }) => {
    const {t} = i18next
    const {data, onDataChange, setErrors, errors} = useBaseObjectForm({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        roles: [],
        status: true,
        password: '',
        time_card_id: '',
        tachograph_card_id: '',
        ...selected
    })

    const [loading, setLoading] = useState(false)
    const onSubmit = (e) => {
        if (e) e.preventDefault()
        setLoading(true)

        if (isUpdating) updateUser(selected.id, {...data}).then(r => {
            if (r && r.data && onSave) {
                onSave(r.data, selected.listIndex)
                showNotification({color: 'green', message: t('user_updated')})
                closeModal('userModal')
            }
            setLoading(false)
        }).catch(e => {
            setErrors(getResponseErrors(e))
            setLoading(false)
        })
        else storeUser({...data}).then(r => {
            if (r && r.data && onCreate) {
                showNotification({color: 'green', message: t('user_created')})
                onCreate(r.data)
                closeModal('userModal')
            }
            setLoading(false)
        }).catch(e => {
            setErrors(getResponseErrors(e))
            setLoading(false)
        })
    }

    return <>
        <div style={{position: 'relative'}}>
            <LoadingOverlay visible={loading}/>
            <form onSubmit={onSubmit}>

                <TextInput label={t('first_name')}
                           value={data.first_name}
                           error={getErrorMessage(errors, 'first_name')}
                           onChange={(e) => onDataChange({first_name: e.target.value})}
                />

                <TextInput label={t('last_name')}
                           value={data.last_name}
                           error={getErrorMessage(errors, 'last_name')}
                           onChange={(e) => onDataChange({last_name: e.target.value})}
                />

                <TextInput label={t('email')}
                           value={data.email}
                           error={getErrorMessage(errors, 'email')}
                           onChange={(e) => onDataChange({email: e.target.value})}
                />

                <TextInput label={t('phone')}
                           value={data.phone}
                           error={getErrorMessage(errors, 'phone')}
                           onChange={(e) => onDataChange({phone: e.target.value})}
                />

                {
                    !isUpdating && <PasswordInput label={t('password')}
                                                  value={data.password}
                                                  error={getErrorMessage(errors, 'password')}
                                                  onChange={(e) => onDataChange({password: e.target.value})}
                    />
                }

                <TextInput label={t('time_card_id')}
                           value={data.time_card_id}
                           error={getErrorMessage(errors, 'time_card_id')}
                           onChange={(e) => onDataChange({time_card_id: e.target.value})}
                />

                <TextInput label={t('tachograph_card_id')}
                           value={data.tachograph_card_id}
                           error={getErrorMessage(errors, 'tachograph_card_id')}
                           onChange={(e) => onDataChange({tachograph_card_id: e.target.value})}
                />

                <Switch mt={15} mb={15}
                        label={<span style={{fontSize: 12}}>{t('active_user')}</span>}
                        checked={data.status}
                        size={'md'}
                        onChange={(event) => onDataChange({status: event.currentTarget.checked})}
                />

                <MultiSelect label={t('roles')}
                             value={data.roles}
                             error={getErrorMessage(errors, 'roles')}
                             onChange={(items) => onDataChange({roles: items})}
                             data={roleOptions}
                />

                <Button compact mt={20} type={"submit"}>{t('save')}</Button>
            </form>
        </div>
    </>
}

export default UserForm

export const MantineThemeObject: any = {
    cursorType: 'pointer',
    fontFamily: `'Poppins', sans-serif`,
    defaultRadius: 2,
    loader: 'oval',
    dateFormat: 'YYYY-MMMM-DD',
    colors: {
        brand: [
            // '#0b021a', // shade is used as text color with dark color scheme
            '#fffdfd',
            // variant='light' background color
            // variant='outline background color dimmed
            // variant='subtle hover background color

            '#e3b0bc',
            //reacts to variant='light' hover background color

            '#dobfff',
            //reacts to variant='light/subtle' font color

            '#FB88AA',
            '#D60644',

            '#BA053B',
            // default shade is considered to be the body background color, input border
            // reacts to variant='outlined'

            '#c4191e',
            // default primary color

            '#93042E',
            // default hover color

            '#a70535',
            // default dark scheme primary color

            '#93042E',
            // default dark scheme hover color,
        ],
        dark: [
            '#C1C2C5',
            '#A6A7AB',
            '#909296',
            '#5C5F66',
            '#373A40',
            '#2C2E33',
            '#25262B',
            '#1A1B1E',
            '#141517',
            '#1A0326'
        ],
        backgrounds: [
            '#000',
            '#282828',
            '#212121',
            '#fff',
            '#eee',
        ]
    },
    primaryColor: 'brand',
    activeStyles: {transform: 'scale(0.98)'},
    components: {
        Button: {
            styles: () => ({
                label: {
                    fontWeight: 500,
                },
                root: {
                    padding: '0 15px'
                }
            }),
        },
        TextInput: {
            styles: () => ({
                label: {
                    fontSize: 12,
                },
                input: {
                    fontSize: 12,
                    '::placeholder': {fontSize: 12,}
                },
            }),
        },
        Checkbox: {
            styles: () => ({
                label: {
                    fontSize: 12,
                },
                input: {
                    fontSize: 12,
                    '::placeholder': {fontSize: 12,}
                },
            }),
        },
        Modal: {
            styles: () => ({
                title: {
                    zIndex: 5
                },
            }),
        },
        Select: {
            defaultProps: {
                withinPortal: true
            },
            styles: () => ({
                label: {
                    fontSize: 12,
                },
                input: {
                    fontSize: 12,
                    '&::placeholder': {}
                },
                item: {
                    fontSize: 12,
                }
            }),
        },
        MultiSelect: {
            styles: () => ({
                label: {
                    fontSize: 12,
                },
                searchInput: {
                    fontSize: 12,
                    '&::placeholder': {
                        fontSize: 12,
                    }
                },
                item: {
                    fontSize: 12,
                }
            }),
        },
        Textarea: {
            styles: () => ({
                label: {
                    fontSize: 12,
                },
                input: {
                    'input': {
                        fontSize: 12
                    },
                    fontSize: 12,
                    '::placeholder': {fontSize: 12,}
                },
            }),
        },
        PasswordInput: {
            styles: () => ({
                label: {
                    fontSize: 12,
                },
                input: {
                    'input': {
                        fontSize: 12
                    },
                    fontSize: 12,
                    '::placeholder': {fontSize: 12,}
                },
            }),
        },
        InputWrapper: {
            styles: () => ({
                label: {
                    fontSize: 12,
                },
                input: {
                    fontSize: 12,
                    '::placeholder': {fontSize: 12,}
                },
            }),
        },
        DatePickerInput: {
            defaultProps: {
                popoverProps: {
                    withinPortal: true
                }
            },
            styles: () => ({
                label: {
                    fontSize: 12,
                },
                input: {
                    fontSize: 12,
                    '::placeholder': {fontSize: 12,}
                },
            }),
        },
    }
}

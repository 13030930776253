import axios from "axios";
import {showNotification} from "@mantine/notifications";

const apiURL = import.meta.env.VITE_API_URL

export const sanctumConfig = {
    apiUrl: apiURL,
    csrfCookieRoute: "sanctum/csrf-cookie",
    signInRoute: "api/auth/login",
    signOutRoute: "api/auth/logout",
    userObjectRoute: "api/auth/profile",
};

//TODO Add sentry
const API = axios.create({
    // @ts-ignore
    baseURL:  apiURL + '/api',
    withCredentials: true,
    headers: {
        // @ts-ignore
        common: {
            'Accept': 'application/json'
        }
    },
});
API.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status >= 500) {
        showNotification({color:'red', message:'Sistemos klaida'})
        // captureException(error)
    }
    if (error.response.status === 401 || error.response.status === 419) {
        redirect()
        // captureException(error)
    }

    return Promise.reject(error)
})

const redirect = () => {
    if (!(
        excludedRedirectRoutes.includes(window.location.pathname)
        || window.location.pathname.match(/forgot-password\/.*/)
        || window.location.pathname.match(/new-password\/.*/)
        || window.location.pathname.match(/register\/.*/)
    )) {
        window.location.href = '/login'
    }

}

const excludedRedirectRoutes = [
    '/forgot', '/new-password', '/login', '/register',
]

export {API}

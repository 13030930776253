import {createContext, useState, useContext} from "react";

const AppSettingsContext = createContext({
    appSettings: undefined,
    language: 'lt',
    theme: 'dark',
    roles: [],
    setAppSettings: undefined,
    setLanguage: undefined,
    setTheme: undefined,
    setRoles: undefined
})

export const AppSettings = ({children}) => {
    const [appSettings, setAppSettings] = useState({});
    const [language, setLanguage] = useState('lt');
    const [theme, setTheme] = useState('dark');
    const [roles, setRoles] = useState([]);

    return <AppSettingsContext.Provider
        value={{appSettings, language, theme, roles, setAppSettings, setLanguage, setTheme, setRoles}}>
        {children}
    </AppSettingsContext.Provider>
}

export const useAppSettings = () => {
    const context = useContext(AppSettingsContext);

    if (!context)
        throw new Error('useAppSettings should only be used inside <AppSettings />')

    return context
}

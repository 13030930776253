import '../sass/app.scss'
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';

import {Sanctum} from 'react-sanctum';
import {AppSettings} from "./providers/AppProvider";
import {AppWrapper} from "./AppWrapper";
import {sanctumConfig} from "./backend/api";

const app = ReactDOM.createRoot(document.getElementById('root')!);

app.render(
    <Router>
        <Sanctum config={sanctumConfig}>
            <AppSettings>
                <AppWrapper/>
            </AppSettings>
        </Sanctum>
    </Router>
)

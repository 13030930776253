import {Button, Grid, LoadingOverlay, Table, TextInput, Title} from "@mantine/core";
import {Check, Search} from "tabler-icons-react";
import {useEffect, useState} from "react";
import TableLoadingSkeleton from "../../components/TableLoadingSkeleton";
import {getTrailers} from "../../backend/axios";
import {closeModal, openModal} from "@mantine/modals";
// @ts-ignore
import moment from "moment";
import {upperFirst} from "@mantine/hooks";
import i18next from "i18next";
import {globalStyles} from "../../AppRouter";
import TrailerForm from "../../forms/TrailerForm";

const TrailerList = () => {
    const {classes: globalClasses} = globalStyles()
    const {t} = i18next
    const [data, setData] = useState([])
    const [filter, setFilter] = useState({
        query: ''
    })
    const [loading, setLoading] = useState(false)

    const getData = (e: any = undefined) => {
        if (e) e.preventDefault()
        setLoading(true)
        getTrailers().then(resp => {
            if (resp && resp.data) setData(resp.data)
            setLoading(false)
        }).catch(() => setLoading(false))
    }

    useEffect(() => {
        getData()
    }, [])

    const openCreateModal = () => {
        openModal({
            modalId: 'trailerModal',
            title: t('new_trailer'),
            centered: true,
            overlayProps: {
                opacity: 0.2
            },
            children: <TrailerForm onCreate={onCreate}/>
        })
    }

    const openUpdateModal = (item, index) => {
        openModal({
            modalId: 'trailerModal',
            title: t('update_trailer'),
            centered: true,
            overlayProps: {
                opacity: 0.2
            },
            children: <TrailerForm onSave={onSave} isUpdating={true} selected={{...item, listIndex: index}}/>
        })
    }

    const onSave = (item: any, index) => {
        let _trailers: any = [...data]
        _trailers[index] = item
        setData(_trailers)
        closeModal('trailerModal')
    }

    const onCreate = (item: any) => {
        let _trailers: any = [...data]
        _trailers.unshift(item)
        setData(_trailers)
        closeModal('trailerModal')
    }

    return <>
        <section className={'main'}>

            <div className={'main-content ' + globalClasses.card}>
                <div className={globalClasses.block_header}>
                    <Title order={6}>{t('page_trailers')}</Title>
                    <Button compact size={'xs'} variant={'filled'} onClick={() => openCreateModal()}>
                        {t('add_trailer')}
                    </Button>
                </div>

                <Grid align={'flex-end'} p={0} mt={15}>
                    <Grid.Col lg={8} md={8} sm={12}>
                        <TextInput placeholder={t('search') || ''}
                                   variant={"unstyled"}
                                   styles={{root: {borderBottom: "1px solid #e8e9ec"}}}
                                   icon={<Search size={20}
                                                 strokeWidth={1.2}/>}
                                   value={filter.query}
                                   onChange={(e) => setFilter({...filter, query: e.target.value})}/>

                    </Grid.Col>
                </Grid>
                <div className={globalClasses.table_wrapper}>
                    <LoadingOverlay visible={loading} loaderProps={{size: 'sm'}} zIndex={5}/>
                    <Table fontSize={'xs'} highlightOnHover className={'productListTable'}
                           style={{minWidth: 500}}>
                        <thead>
                        <tr>
                            <th>{t('manufacturer')}</th>
                            <th>{t('plate')}</th>
                            <th>{t('vin')}</th>
                            <th>{t('manufacture_date')}</th>
                            <th>{t('type')}</th>
                            <th>{t('moto_hours')}</th>
                        </tr>
                        </thead>

                        <tbody>
                        {loading ? <TableLoadingSkeleton columnCount={6} rowCount={4}/>
                            : data && data.length > 0 && data.map((item: any, index) => <tr key={`user-${index}`}
                                                                                            style={{cursor: 'pointer'}}
                                                                                            onClick={() => openUpdateModal(item, index)}>

                            <td>{item.manufacturer}</td>
                            <td>{item.plate}</td>
                            <td>{item.vin}</td>
                            <td>{item.manufacture_date}</td>
                            <td>{upperFirst(item.type)}</td>
                            <td style={{verticalAlign: 'bottom'}}>{item.moto_hours_required ?
                                <Check size={18}/> : ''}</td>
                        </tr>)
                        }
                        </tbody>
                    </Table>
                </div>
            </div>
        </section>
    </>
}

export default TrailerList



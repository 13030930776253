import '../sass/app.scss'
import {createStyles} from "@mantine/core";
import {Notifications} from "@mantine/notifications";
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {ModalsProvider} from "@mantine/modals";

import {useEffect, useState} from "react";
import {NavbarNested} from "./layout/Navbar";
import Login from "./pages/auth/Login";
import {useSanctum} from "react-sanctum";
import UserList from "./pages/user/UserList";
import TrailerList from "./pages/trailers/TrailerList";
import {Authenticated, RequireAuth} from "./providers/AuthProvider";
import CarList from "./pages/cars/CarList";
import TripSheetList from "./pages/tripSheets/TripSheetList";
import TripSheetCreate from "./pages/tripSheets/TripSheetCreate";
import TripSheetShow from "./pages/tripSheets/TripSheetShow";
import Settings from "./pages/settings/Settings";

import 'dayjs/locale/ru'
import 'dayjs/locale/lt'

import i18next from 'i18next';
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import FsBackend from "i18next-fs-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import {getSetLang} from "./helpers/helpers";
import ContentHeader from "./layout/ContentHeader";

const AppRouter = () => {
    const location = useLocation()
    const [hideTools, setHideTools] = useState(false)
    const {authenticated} = useSanctum()
    const {classes} = useStyles()

    useEffect(() => {
        if (location.pathname.match('/login|forgot|register\/'))
            setHideTools(true)
        else
            setHideTools(false)
    }, [location.pathname])


    return <>
        <Notifications position={'top-right'} autoClose={5000}/>
        <ModalsProvider modalProps={{overlayProps: {opacity: 0.2}}}>

            <ContentHeader hideTools={hideTools}/>

            <div
                style={{display: 'flex', flexDirection: 'row', flex: 1, height:'calc(100% - 58px)'}}>
                {!hideTools && <NavbarNested/>}

                <div id={'content_wrapper_container'} className={classes.content_wrapper}>

                    <Routes>
                        <Route element={<Authenticated/>}>
                            <Route path='login' element={<Login/>}/>
                        </Route>
                        <Route element={<RequireAuth/>}>
                            <Route path='dashboard' element={<></>}/>
                            <Route path='cars' element={<CarList/>}/>
                            <Route path='trailers' element={<TrailerList/>}/>
                            <Route path='users' element={<UserList/>}/>

                            <Route path='my-travels' element={<TripSheetList type={'my'}/>}/>
                            <Route path='my-travels/:id'
                                   element={<TripSheetShow key={'show_my_travel'} isShowing={true}/>}/>
                            <Route path='my-travels/:id/edit'
                                   element={<TripSheetShow key={'show_my_travel_edit'}/>}/>
                            <Route path='my-travels/create' element={<TripSheetCreate/>}/>

                            <Route path='travels' element={<TripSheetList type={'all'}/>}/>
                            <Route path='travels/:id' element={<TripSheetShow key={'show_travel'}/>}/>


                            <Route path='settings' element={<Settings/>}/>
                        </Route>

                        <Route path='*' element={<Navigate to={authenticated ? 'dashboard' : 'login'}
                                                           replace/>}/>

                    </Routes>
                </div>
            </div>
        </ModalsProvider>
    </>
}

export default AppRouter

// @ts-ignore
i18next
    .use(ChainedBackend)
    .init({
        lng: localStorage.getItem('gvr-lang'),
        fallbackLng: localStorage.getItem('gvr-lang') ? getSetLang() : "lt",
        backend: {
            backends: [
                LocalStorageBackend,
                HttpBackend,
                FsBackend
            ],
            backendOptions: [{
                expirationTime: 0.01 * 24 * 60 * 60 * 1000
                // saugomas timestamp kuris po ~9minuciu gauna nauja translation list kai perkraunamas puslapis
            }, {
                loadPath: '/locales/{{lng}}/translations.json'
            }],
        },
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        },
        react: {
            useSuspense: true
        }
    });

const useStyles = createStyles((theme) => ({
    data_wrapper: {
        position: 'relative',
        display: 'flex',
        flex: 1,
        width: '100%'
    },
    content_wrapper: {
        display: 'flex',
        flexDirection: 'column',
        background: theme.colorScheme === 'dark' ? '#171717' : '#eee',
        overflow: 'auto',
        flex: 1,
        padding: 20,
    }
}));


export const globalStyles = createStyles((theme) => ({
    table_wrapper: {
        overflow: 'auto', marginTop: 25, position: 'relative',
        'thead': {
            position: 'sticky',
            top: 0,
            background: `${theme.colorScheme === 'dark' ? theme.colors.dark[6] : '#ddd'} !important`,
            boxShadow: '0 0 6px 1px rgba(0, 0, 0, 10%)'
        }
    },
    card: {
        border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.backgrounds[2] : '#ddd'}`,
        width: '100%',
        minHeight: '100%',
        padding: 15,
        boxShadow: '0 5px 4px 1px rgba(0,0,0,8%)',
        background: theme.colorScheme === 'dark' ? '#212121' : '#fff',
    },
    block_header: {
        borderBottom: '1px solid #ddd',
        minHeight: 42,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        justifyContent: 'space-between'
    }
}));

import {createContext, useEffect, useState} from "react";
import {MantineProvider} from "@mantine/core";
import {MantineThemeObject} from "../theme/themeObject";
import {DatesProvider} from "@mantine/dates";
import {getSetLang} from "./helpers/helpers";
import AppRouter from "./AppRouter";

export const ThemeContext = createContext(null);

export const AppWrapper = () => {
    const [colorScheme, setColorScheme] = useState('dark')

    useEffect(() => {
        if (!localStorage.getItem('gvr-lang')) localStorage.setItem('gvr-lang', 'lt')
    }, [])

    const toggleColorScheme = () => {
        if (colorScheme === 'dark') {
            setColorScheme('light')
            window.localStorage.setItem('gvr-theme', 'light')
        } else {
            window.localStorage.setItem('gvr-theme', 'dark')
            setColorScheme('dark')
        }
    }

    useEffect(() => {
        if (window.localStorage.getItem('gvr-theme')) { // @ts-ignore
            setColorScheme(window.localStorage.getItem('gvr-theme'))
        }
    }, [])

    return <ThemeContext.Provider value={{colorScheme: colorScheme, toggleColorScheme: toggleColorScheme}}>
        <MantineProvider withGlobalStyles withNormalizeCSS theme={{...MantineThemeObject, colorScheme: colorScheme}}>
            <DatesProvider settings={{locale: getSetLang(false), firstDayOfWeek: 1}}>
                <AppRouter/>
            </DatesProvider>
        </MantineProvider>
    </ThemeContext.Provider>
}

import {TextInput, Button, Select, Stack, Group, Divider, LoadingOverlay, createStyles, Checkbox} from "@mantine/core";
import {DatePickerInput} from "@mantine/dates";
import {useBaseObjectForm} from "../../customHooks/hooks";
import {createTripSheet, getCarsPicker, getTrailersPicker, getUserPicker} from "../../backend/axios";
import {getErrorMessage, getResponseErrors} from "../../helpers/helpers";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
// @ts-ignore
import moment from "moment";
import i18next from "i18next";

const TripSheetCreate = () => {
    const navigate = useNavigate()
    const {t} = i18next
    const {data, onDataChange, errors, setErrors} = useBaseObjectForm({
        car_id: '',
        trailer_id: '',
        ad_blue: '',
        fuel_start: '',
        moto_hours_start: '',
        trip_sheet_start_at: new Date(),
        add_secondary_driver: false
    })
    const {classes} = useStyles()
    const [loading, setLoading] = useState(false)
    const [vehicleOptions, setVehicleOptions] = useState([])
    const [driverOptions, setDriverOptions] = useState([])
    const [trailerOptions, setTrailerOptions] = useState([])

    const onSubmit = (e) => {
        if (e) e.preventDefault()
        setLoading(true)

        let _trip_sheet_start_at = ''
        if (data.trip_sheet_start_at) _trip_sheet_start_at = moment(data.trip_sheet_start_at).format('YYYY-MM-DD')

        createTripSheet({...data, trip_sheet_start_at: _trip_sheet_start_at}).then(r => {
            if (r && r.data && r.data.id) {
                navigate(`/travels/${r.data.id}`)
            }
            setLoading(false)
        })
            .catch(e => {
                setErrors(getResponseErrors(e))
                setLoading(false)
            })
    }

    useEffect(() => {
        getCarsPicker().then(r => {
            if (r && r.data) setVehicleOptions(r.data)
        })
        getUserPicker().then(r => {
            if (r && r.data) setDriverOptions(r.data)
        })
        getTrailersPicker().then(r => {
            if (r && r.data) setTrailerOptions(r.data)
        })
    }, [])

    return <>
        <section className={'main'}>

            <div className={classes.sheet}>
                <LoadingOverlay visible={loading}/>
                <form onSubmit={onSubmit}>
                    <h5 className={'muted lean-text'}>{t('page_new_tripSheet')}</h5>

                    <Checkbox label={t('secondary_driver_exists')}
                              mb={10}
                              value={data.add_secondary_driver}
                              onChange={(e) => onDataChange({add_secondary_driver: e.target.checked})}/>

                    {
                        data.add_secondary_driver && <Select searchable label={t('secondary_driver')}
                                                             data={driverOptions || []}
                                                             error={getErrorMessage(errors, 'secondary_driver_id')}
                                                             value={data.secondary_driver_id}
                                                             onChange={(item) => onDataChange({secondary_driver_id: item})}/>
                    }

                    <Stack spacing={5}>
                        <DatePickerInput label={t('tripSheet_start')}
                                         value={data.trip_sheet_start_at}
                                         defaultLevel={'year'}
                                         valueFormat={'YYYY-MM-DD'}
                                         firstDayOfWeek={1}
                                         onChange={(date) => onDataChange({trip_sheet_start_at: date})}
                                         error={getErrorMessage(errors, 'trip_sheet_start_at')}
                        />

                        <Divider mb={5} mt={15}/>

                        <Select searchable label={t('truck')}
                                data={vehicleOptions || []}
                                error={getErrorMessage(errors, 'car_id')}
                                value={data.car_id}
                                onChange={(item) => onDataChange({car_id: item})}/>

                        <Select searchable label={t('trailer')}
                                data={trailerOptions || []}
                                error={getErrorMessage(errors, 'trailer_id')}
                                value={data.trailer_id}
                                onChange={(item) => onDataChange({trailer_id: item})}/>

                        <Group>
                            <TextInput style={{flex: '1'}}
                                       label={t('ad_blue_left')}
                                       error={getErrorMessage(errors, 'ad_blue')}
                                       value={data.ad_blue}
                                       onChange={(e) => onDataChange({ad_blue: e.target.value})}/>
                            <TextInput style={{flex: '1'}}
                                       label={t('fuel_left')}
                                       error={getErrorMessage(errors, 'fuel_start')}
                                       value={data.fuel_start}
                                       onChange={(e) => onDataChange({fuel_start: e.target.value})}/>
                        </Group>

                        <Divider mb={5} mt={15}/>

                        <TextInput label={t('moto_hours_start')}
                                   error={getErrorMessage(errors, 'moto_hours_start')}
                                   value={data.moto_hours_start}
                                   onChange={(e) => onDataChange({moto_hours_start: e.target.value})}/>
                    </Stack>

                    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <Button size={"sm"} compact mt={30} mb={5} type={'submit'}>{t('create')}</Button>
                    </div>
                </form>
            </div>
        </section>

    </>
}
export default TripSheetCreate

const useStyles = createStyles((theme) => ({
    sheet: {
        padding: 15,
        border: `1px solid ${theme.colorScheme === 'dark' ? '#6e6e6e' : '#ddd'}`,
        margin: 'auto',
        maxWidth: 450,
        width: '100%',
        position: 'relative',
        background: theme.colorScheme === 'dark' ? '#171717' : '#fff',
    },
}));

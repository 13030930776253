import i18next from "i18next";
import {useState} from "react";
import {FileInput, Button} from "@mantine/core";
import {storeSettings} from "../../backend/axios";
import {showNotification} from "@mantine/notifications";

const Settings = () => {
    const [translationFile, setTranslationFile] = useState<File | null>(null);

    const onSaveSettings = () => {
        storeSettings().then(r => {
            if (r && r.status === 200) showNotification({message: i18next.t('changes_saved')})
        })
    }

    return <section className={'main'}>

        <h5 className={'muted lean-text'}>{i18next.t('page_settings')}</h5>

        <div style={{maxWidth: 350}}>
            <FileInput style={{flex: 1}} label={i18next.t('translation_file_input')}
                       value={translationFile}
                       onChange={setTranslationFile}/>
            <Button mt={15} size={'xs'} onClick={() => onSaveSettings()}>{i18next.t('save_changes')}</Button>
        </div>
    </section>
}

export default Settings

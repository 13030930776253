import {API} from "./api";
import {createFileFormData} from "../helpers/helpers";

export const getProducts = (filter: any = undefined, getToken: any = undefined) => {
    return API.get(`/products`, {
        params: filter,
        cancelToken: getToken
    })
}


export const deletePricingRule = (id: any = undefined, getToken: any = undefined) => {
    return API.delete(`/pricing-rules/${id}`, {
        cancelToken: getToken
    })
}

export const searchProduct = (filter: any = undefined, getToken: any = undefined) => {
    return API.get(`/search/product`, {
        params: filter,
        cancelToken: getToken
    })
}

export const getPermissions = (getToken: any = undefined) => {
    return API.get(`/permissions`, {
        cancelToken: getToken
    })
}

// ---------------- CAR

export const getCars = (getToken: any = undefined) => {
    return API.get(`/cars`, {
        cancelToken: getToken
    })
}

export const getCarsPicker = (getToken: any = undefined) => {
    return API.get(`/pickers/cars`, {
        cancelToken: getToken
    })
}

export const getUserPicker = (getToken: any = undefined) => {
    return API.get(`/pickers/users`, {
        cancelToken: getToken
    })
}

export const getCar = (id: any = undefined, getToken: any = undefined) => {
    return API.get(`/cars/${id}`, {
        cancelToken: getToken
    })
}

export const storeCar = (data: any = undefined, getToken: any = undefined) => {
    return API.post(`/cars`, data, {
        cancelToken: getToken
    })
}
export const updateCar = (id: any = undefined, data: any = undefined, getToken: any = undefined) => {
    return API.put(`/cars/${id}`, data, {
        cancelToken: getToken
    })
}


// ---------------- Trailer

export const getTrailers = (getToken: any = undefined) => {
    return API.get(`/trailers`, {
        cancelToken: getToken
    })
}

export const getTrailersPicker = (getToken: any = undefined) => {
    return API.get(`/pickers/trailers`, {
        cancelToken: getToken
    })
}

export const getTrailer = (id: any = undefined, getToken: any = undefined) => {
    return API.get(`/trailers/${id}`, {
        cancelToken: getToken
    })
}

export const storeTrailer = (data: any = undefined, getToken: any = undefined) => {
    return API.post(`/trailers`, data, {
        cancelToken: getToken
    })
}
export const updateTrailer = (id: any = undefined, data: any = undefined, getToken: any = undefined) => {
    return API.put(`/trailers/${id}`, data, {
        cancelToken: getToken
    })
}


// ---------------- Users

export const getUsers = (getToken: any = undefined) => {
    return API.get(`/users`, {
        cancelToken: getToken
    })
}

export const getUser = (id: any = undefined, getToken: any = undefined) => {
    return API.get(`/users/${id}`, {
        cancelToken: getToken
    })
}

export const storeUser = (data: any = undefined, getToken: any = undefined) => {
    return API.post(`/users`, data, {
        cancelToken: getToken
    })
}
export const updateUser = (id: any = undefined, data: any = undefined, getToken: any = undefined) => {
    return API.put(`/users/${id}`, data, {
        cancelToken: getToken
    })
}

// ---------------- TripSheets

export const getAllTripSheets = (filter = undefined, getToken: any = undefined) => {
    return API.get(`/tripSheets`, {
        params: filter,
        cancelToken: getToken
    })
}

export const getMyTripSheets = (filter = undefined, getToken: any = undefined) => {
    return API.get(`/my/tripSheets`, {
        params: filter,
        cancelToken: getToken
    })
}

export const getTripSheet = (id: any = undefined, getToken: any = undefined) => {
    return API.get(`/tripSheets/${id}`, {
        cancelToken: getToken
    })
}

export const createTripSheet = (data: any = undefined, getToken: any = undefined) => {
    return API.post(`/my/tripSheets`, data, {
        cancelToken: getToken
    })
}
export const updateTripSheet = (id: any = undefined, data: any = undefined, getToken: any = undefined) => {
    return API.put(`/tripSheets/${id}`, data, {
        cancelToken: getToken
    })
}

export const createTripForTripSheet = (id, data: any = undefined, getToken: any = undefined) => {
    return API.post(`/tripSheets/${id}/trip`, data, {
        cancelToken: getToken
    })
}

export const createMySpendingForTripSheet = (id, data: any = undefined, getToken: any = undefined) => {
    return API.post(`/my/tripSheets/${id}/receipt`, data, {
        cancelToken: getToken
    })
}
// ---------------- User profile/password

export const updateUserProfile = (data: any = undefined, getToken: any = undefined) => {
    return API.put(`/profile`, data, {
        cancelToken: getToken
    })
}

export const updateUserPassword = (data: any = undefined, getToken: any = undefined) => {
    return API.put(`/profile/password`, data, {
        cancelToken: getToken
    })
}

export const storeSettings = (data: any = undefined, getToken: any = undefined) => {
    return API.post(`/settings`, data, {
        cancelToken: getToken
    })
}

export const storeTranslationFile = (file: any = undefined, getToken: any = undefined) => {
    return API.post(`/settings/translation`, createFileFormData(file, 'file'), {
        cancelToken: getToken
    })
}

export const getRolesPicker = (getToken: any = undefined) => {
    return API.get(`/pickers/roles`, {
        cancelToken: getToken
    })
}

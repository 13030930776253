import {ActionIcon, Button, createStyles, Popover} from "@mantine/core";
import {getSetLang, setLang} from "../helpers/helpers";
import {Moon, Sun} from "tabler-icons-react";
import {useContext} from "react";
import {ThemeContext} from "../AppWrapper";
import i18next from "i18next";
import {useNavigate} from "react-router-dom";
import {ProfileButton} from "../components/ProfileButton";
// @ts-ignore
import logo from "../../images/cropped-fav-1-180x180.png";

const ContentHeader = ({hideTools = false}) => {

    const {t} = i18next
    const navigate = useNavigate()
    const {colorScheme, toggleColorScheme}: any = useContext(ThemeContext);
    const {classes} = useStyles()

    const changeLang = (lng) => {
        if (lng) {
            if (lng === 'lt') setLang('ru')
            else setLang('lt')
        }
    }

    return <div className={classes.header}>
        <div className={classes.logo}>
            <img src={logo} alt={''}/>
        </div>

        <div className={classes.header_content}>
            {!hideTools ?
                <Button color={colorScheme == 'dark' ? '' : 'dark'} size={'xs'} compact variant={'filled'} radius={5}
                        onClick={() => navigate('/my-travels/create')}>{t('create_tripSheet')}</Button>
                : <div/>
            }
            <div className={classes.button_wrapper}>


                <Popover styles={{
                    dropdown: {
                        padding: 0
                    }
                }}>
                    <Popover.Target>
                        <ActionIcon variant={'default'} radius={10}>
                            <span style={{fontSize: 13}}>{getSetLang(false)}</span>
                        </ActionIcon>
                    </Popover.Target>
                    <Popover.Dropdown>
                        {['lt', 'ru'].map((lng, index) => <ActionIcon radius={0}
                                                                      key={`lang-selector-${index}`}
                                                                      variant={getSetLang(false) === lng ? 'filled' : 'subtle'}
                                                                      color='dark'
                                                                      onClick={() => changeLang(getSetLang(false))}>
                                <span style={{fontSize: 13}}>{lng}</span>
                            </ActionIcon>
                        )}
                    </Popover.Dropdown>
                </Popover>

                <ActionIcon
                    variant={'default'}
                    radius={10}
                    onClick={() => toggleColorScheme()}
                    sx={(theme) => ({
                        backgroundColor:
                            theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.dark[6],
                    })}>
                    {colorScheme === 'dark' ? <Sun size="1rem"/> : <Moon size="1rem"/>}
                </ActionIcon>

                <ProfileButton/>

            </div>
        </div>
    </div>
}
export default ContentHeader

const useStyles = createStyles((theme) => ({
    header: {
        zIndex:5,
        display: 'flex',
        gap: 7,
        width: '100%',
        background: '#fff',
        padding: '5px 10px',
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.backgrounds[2] : theme.colors.backgrounds[3],
        boxShadow: `0px 3px 4px 1px ${theme.colorScheme === 'dark' ? '#28282800' : 'rgba(117,117,121,0.15)'}`,
    },
    header_content: {
        display: 'flex',
        gap: 7,
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    button_wrapper: {
        display: 'flex',
        gap: 10,
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    logo: {
        maxHeight: 48,
        minWidth: 60,
        objectFit: 'contain',
        'img': {
            objectFit: 'contain',
            height: '100%'
        }
    }
}))

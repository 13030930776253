import {createStyles} from '@mantine/core';
import {LinksGroup} from '../components/layout/LinksGroup';
import {
    BoxSeam, FileDescription, FileSpreadsheet, Settings, Truck, Users
} from "tabler-icons-react";
import {checkPermissions} from "../helpers/helpers";
// @ts-ignore
import logo from '../../images/cropped-fav-1-180x180.png'
import i18next from "i18next";


export function NavbarNested() {
    const {classes} = useStyles();

    const navLinks = [
        {label: i18next.t('page_users'), icon: Users, link: '/users'},
        {label: i18next.t('page_cars'), icon: Truck, link: '/cars'},
        {label: i18next.t('page_trailers'), icon: BoxSeam, link: '/trailers'},
        {label: i18next.t('page_my_tripSheets'), icon: FileSpreadsheet, link: '/my-travels/'},
        {label: i18next.t('page_all_tripSheets'), icon: FileDescription, link: '/travels'},
        {label: i18next.t('page_settings'), icon: Settings, link: '/settings'},
    ];

    return <div key={'page_navbar'} id={'page_navbar'} className={classes.navbar}>

        <div className={'nav-links'}>
            <div>
                {
                    navLinks.map((item: any, index) => item.permissions ? checkPermissions(item.permissions) &&
                        <LinksGroup key={`nav-link-${index}`}
                                    {...item}/>
                        : <LinksGroup key={`nav-link-${index}`}
                                      {...item}/>)
                }
            </div>
        </div>
    </div>
}

const useStyles = createStyles((theme) => ({
    navbar: {
        paddingTop: 20,
        maxHeight: '100vh',
        overflow: 'auto',
        display: 'flex',
        boxShadow: `0px 3px 4px 1px ${theme.colorScheme === 'dark' ? '#28282800' : 'rgba(117,117,121,0.3)'}`,
        color: 'rgba(117,117,121,0.3)',
        zIndex: 1,
        flexDirection: 'column',
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.backgrounds[2] : theme.colors.backgrounds[3],
        width: '100%',
        minWidth: 68,
        maxWidth: 90,
        paddingBottom: 0,
        '.nav-links': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            width: '100%',
            '>div': {
                width: '100%',
            }
        },
        '.logout-container': {
            marginTop: '100%',
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            gap: 20,
            justifyContent: 'center',
            alignItems: 'center'
        }
    },

    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 68,
        padding: theme.spacing.md,
        color: theme.black,
        boxShadow: '0 2px 6px #00000029',
        'img': {
            width: '100%',
            height: '100%',
        }
    },
}));


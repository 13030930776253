import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useBaseObjectForm} from "../../customHooks/hooks";
import {
    Grid,
    TextInput,
    Button,
    Text,
    Timeline,
    createStyles,
    Table,
    Title,
    Group,
    Badge,
    Divider
} from "@mantine/core";
import {closeAllModals, closeModal, openModal} from "@mantine/modals";
import {DatePickerInput} from "@mantine/dates";
import {getTripSheet, updateTripSheet} from "../../backend/axios";
import {getErrorMessage, getResponseErrors} from "../../helpers/helpers";

// @ts-ignore
import countryOptions from '../../backend/country_list.json'
// @ts-ignore
import moment from "moment";
import {MapPin, StepInto} from "tabler-icons-react";
import i18next from "i18next";
import SpendingModal from "../../components/modals/SpendingModal";
import TripModal from "../../components/modals/TripModal";
import {useSanctum} from "react-sanctum";
import {globalStyles} from "../../AppRouter";
import axios from "axios";
import CloseTripSheetModal from "../../components/modals/CloseTripSheetModal";

const TripSheetShow = ({isShowing = false, itemId = undefined, modalViewing = false}) => {
    const {t} = i18next
    const navigate = useNavigate()
    const {classes: globalClasses} = globalStyles()
    const params = useParams()
    const [loading, setLoading] = useState(false)
    const {classes} = useStyles()
    const {user} = useSanctum()

    const {data, setData, onDataChange, setErrors, errors} = useBaseObjectForm({})

    const sheetId = itemId ? itemId : params.id ? params.id : ''

    const isNotSecondaryDriver = () => {
        if (data.secondary_driver) return Boolean(data.secondary_driver.id !== user.id)
        return true;
    }

    const openNewRoute = (isCreating = false) => {
        openModal({
            modalId: 'newTrip',
            title: t('new_route'),
            centered: true,
            overlayProps: {
                opacity: 0.2
            },
            size: 'lg',
            children: <TripModal id={data.id}
                                 car={data.car}
                                 onSave={(item) => onSaveArray(item, 'trips', closeModal('newTrip'))}
                                 isCreating={isCreating}/>
        })
    }

    const openNewSpending = () => {
        openModal({
            modalId: 'spendingModal',
            title: t('spendings'),
            centered: true,
            overlayProps: {
                opacity: 0.2
            },
            children: <SpendingModal id={data.id}
                                     onSave={(item) => onSaveArray(item, 'receipts', closeModal('spendingModal'))}/>
        })
    }

    const openFinishTripSheet = () => {
        openModal({
            modalId: 'finishTripSheetModal',
            title: t('close_tripSheet'),
            centered: true,
            overlayProps: {
                opacity: 0.2
            },
            children: <CloseTripSheetModal tripSheetData={data} setData={setData}/>
        })
    }

    useEffect(() => {
        setLoading(true)
        if (sheetId) getTripSheet(sheetId)
            .then(r => {
                if (r && r.data && r.data) setData(r.data)
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }, [])

    const onSaveArray = (item, name = '', closeStatement: any = undefined) => {

        if (name) {
            let _arr = [...data[name]]
            _arr.push(item)
            setData({...data, [name]: _arr})
        }
        if (closeStatement) closeStatement()
    }

    const getCarInfo = () => {
        let _info = ''
        if (data.car && data.car.manufacturer) _info = data.car.manufacturer
        if (data.car && data.car.plate) _info = _info + ' ' + data.car.plate
        if (data.trailer) _info = _info + ' / ' + data.trailer.plate
        return _info
    }

    const getCountryByCode = (country = '') => {
        if (country) {
            let _result = countryOptions.find(crc => crc.value == country)
            if (_result) return _result.label
            else return ''
        }
        return ''
    }

    const saveData = () => {
        setLoading(true)
        let _trip_sheet_start_at = ''
        let _trip_sheet_end_at = ''
        if (data.trip_sheet_start_at) _trip_sheet_start_at = moment(data.trip_sheet_start_at).format('YYYY-MM-DD')
        if (data.trip_sheet_end_at) _trip_sheet_end_at = moment(data.trip_sheet_end_at).format('YYYY-MM-DD')

        updateTripSheet(data.id, {
            ...data,
            trip_sheet_start_at: _trip_sheet_start_at,
            trip_sheet_end_at: _trip_sheet_end_at
        })
            .then(r => {
                if (r && r.data) setData(r.data)
                setLoading(false)
            })
            .catch(e => {
                setErrors(getResponseErrors(e))
                setLoading(false)
            })
    }

    const textBlock = (val) => {
        if (val || !isNaN(val)) return <Text component="span"
                                             sx={(theme) => ({
                                                 marginLeft: 5,
                                                 color: theme.colorScheme === 'dark' ? '#fff' : '#000'
                                             })}>{val}</Text>
        else return <></>
    }

    const downloadFile = (_trip) => {
        axios({
            url: `/api/trips/${_trip.id}/download`,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${_trip.trip_stop_at} ${getCountryByCode(_trip.trip_stop_country)} ${_trip.trip_stop_location}.${_trip.cmr_file.split('.').slice(-1)[0]}`); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    }

    return <div style={{marginBottom: 50}}>
        <div className={classes.sheet}>

            <Group align={'center'} pb={20} spacing={10} mt={15}>
                <Title order={4}>{t('page_tripSheet')}</Title>
                {data.status && <Badge color={data.status === 'draft' ? 'gray.8' : 'gray.7'}
                                       variant={data.status === 'draft' ? 'filled' : 'light'}
                                       styles={{root: {fontWeight: 600}}}>
                    {data.status}
                </Badge>}
                {
                    data.status === 'draft' && isNotSecondaryDriver() && !isShowing ?
                        <Button compact disabled={loading} size={'xs'}
                                onClick={() => openFinishTripSheet()}>{t('close_tripSheet')}</Button>
                        :
                        data.status === 'draft' && isNotSecondaryDriver() ? <Button compact
                                                                                    disabled={loading}
                                                                                    size={'xs'}
                                                                                    onClick={() => {
                                                                                        if (modalViewing) closeAllModals()
                                                                                        navigate(`/my-travels/${sheetId}/edit`)
                                                                                    }}>{t('edit')}</Button>
                            : <></>
                }
            </Group>

            {
                !isNaN(data.distance_traveled) && data.distance_traveled > 0 && <div>
                    Bendras nuvažiuotas atstumas: <strong>{data.distance_traveled} km</strong>
                </div>
            }

            <Grid gutter={10} m={-5} p={0}>
                <Grid.Col lg={modalViewing ? 12 : 6} md={modalViewing ? 12 : 12} sm={modalViewing ? 12 : 12}>
                    <div className={globalClasses.card} style={{minHeight: 0}}>
                        <div className={globalClasses.block_header}>
                            <Group align={'baseline'}>
                                <Title order={6} mt={10}>{t('trip_sheet_information')}</Title>
                            </Group>
                        </div>

                        <Grid gutter={10} mt={5} grow>
                            <Grid.Col sm={12} md={6} lg={6}>
                                <DatePickerInput label={t('tripSheet_start')}
                                                 readOnly={data.status !== 'draft'}
                                                 variant={data.status === 'draft' ? 'default' : 'filled'}
                                                 style={{flex: '1'}}
                                                 defaultLevel={'year'}
                                                 valueFormat={'YYYY-MM-DD'}
                                                 firstDayOfWeek={1}
                                                 value={data.trip_sheet_start_at ? new Date(data.trip_sheet_start_at) : null}
                                                 onChange={(dt) => onDataChange({trip_sheet_start_at: dt})}/>

                            </Grid.Col>

                            {
                                data.status === 'closed' && <Grid.Col sm={12} md={6} lg={6}>
                                    <DatePickerInput label={t('tripSheet_end')}
                                                     readOnly={data.status !== 'draft'}
                                                     variant={data.status === 'draft' ? 'default' : 'filled'}
                                                     style={{flex: '1'}}
                                                     defaultLevel={'year'}
                                                     valueFormat={'YYYY-MM-DD'}
                                                     firstDayOfWeek={1}
                                                     value={data.trip_sheet_end_at ? new Date(data.trip_sheet_end_at) : null}
                                                     onChange={(dt) => onDataChange({trip_sheet_end_at: dt})}/>
                                </Grid.Col>
                            }
                        </Grid>

                        <Grid gutter={0}>
                            <Grid.Col span={12}>
                                <TextInput variant={'filled'}
                                           value={data.id || ''}
                                           onChange={() => {
                                           }}
                                           readOnly
                                           label={t('tripSheet_number')}/>
                            </Grid.Col>
                            <Grid.Col span={12}>
                                <TextInput variant={'filled'}
                                           label={t('vehicle_trailer_number')}
                                           value={data.car && getCarInfo() || ''}
                                           readOnly/>

                                <TextInput variant={'filled'}
                                           label={t('driver')}
                                           value={data.driver && `${data.driver.first_name} ${data.driver.last_name}` || ''}
                                           onChange={() => {
                                           }}
                                           readOnly/>

                                {
                                    data.secondary_driver && data.secondary_driver.id &&
                                    <TextInput variant={'filled'}
                                               label={t('secondary_driver')}
                                               value={`${data.secondary_driver.first_name} ${data.secondary_driver.last_name}`}
                                               onChange={() => {
                                               }}
                                               readOnly/>
                                }

                                {
                                    data.moto_hours_start || (data.trailer && data.trailer.moto_hours_required) ? <>
                                            <Divider mt={20} mb={5}/>
                                            <Grid gutter={10} grow>
                                                <Grid.Col sm={12} md={6} lg={6}>
                                                    <TextInput label={t('moto_hours_start')}
                                                               value={data.moto_hours_start || ''}
                                                               onChange={(e) => onDataChange({moto_hours_start: e.target.value})}
                                                               error={getErrorMessage(errors, 'moto_hours_start')}
                                                               style={{flex: '1'}}
                                                               readOnly={data.status !== 'draft'}
                                                               variant={data.status === 'draft' ? 'default' : 'filled'}
                                                    />
                                                </Grid.Col>

                                                {
                                                    data.status === 'closed' && <Grid.Col sm={12} md={6} lg={6}>
                                                        <TextInput label={t('moto_hours_end')}
                                                                   value={data.moto_hours_end || ''}
                                                                   onChange={(e) => onDataChange({moto_hours_end: e.target.value})}
                                                                   error={getErrorMessage(errors, 'moto_hours_end')}
                                                                   style={{flex: '1'}}
                                                                   readOnly={data.status !== 'draft'}
                                                                   variant={data.status === 'draft' ? 'default' : 'filled'}
                                                        />

                                                    </Grid.Col>
                                                }
                                            </Grid>
                                        </>
                                        : <></>
                                }
                            </Grid.Col>
                        </Grid>

                        <div style={{marginTop: 15, display: 'flex', gap: 15, justifyContent: 'flex-end'}}>
                            {
                                data.status === 'draft' && isNotSecondaryDriver() && !isShowing &&
                                <Button variant={'default'} compact onClick={() => saveData()} disabled={loading}>
                                    {t('save_changes')}
                                </Button>
                            }
                        </div>
                    </div>
                </Grid.Col>


                <Grid.Col lg={modalViewing ? 12 : 6} md={modalViewing ? 12 : 12} sm={modalViewing ? 12 : 12}
                          xs={modalViewing ? 12 : 12}>
                    <div className={globalClasses.card}>
                        <div className={globalClasses.block_header}>
                            <Group align={'baseline'}>
                                <Title order={6} mt={10}>{t('route')}</Title>
                                {
                                    data.status === 'draft' && isNotSecondaryDriver() && !isShowing &&
                                    <Button mb={10} size={'xs'} disabled={loading} compact variant={'filled'}
                                            onClick={() => openNewRoute(!(data.trips && data.trips.length > 0))}>
                                        {
                                            data.trips && data.trips.length > 0
                                                ? t('add_trip_stop')
                                                : t('add_trip_start')
                                        }
                                    </Button>
                                }
                            </Group>
                        </div>
                        {
                            data && data.trips && data.trips.length > 0 && <>
                                <Timeline active={data.trips.length - 1} bulletSize={24} lineWidth={2} mt={10}>
                                    {
                                        data.trips.map((item, index) => <Timeline.Item key={`stop-index-${index}`}
                                                                                       lineVariant="dashed"
                                                                                       bullet={index === 0 || index + 1 == data.trips.length ?
                                                                                           <MapPin size={16}
                                                                                                   strokeWidth={2}/> :
                                                                                           <StepInto size={16}
                                                                                                     strokeWidth={2}/>}
                                                                                       title={item.trip_stop_at}>

                                            {index !== 0 && <Text color="dimmed" size="sm">{t('distance_traveled')}:
                                                <strong>{textBlock(`${Number(item.odometer) - Number(data.trips[index - 1].odometer)} km`)}</strong>
                                            </Text>}

                                            <Text color="dimmed" size="sm">{t('location')}:
                                                {textBlock(`${item.trip_stop_country ? getCountryByCode(item.trip_stop_country) : ''} ${item.trip_stop_location ? item.trip_stop_location : ''}`)}

                                            </Text>

                                            <Text color="dimmed" size="sm">{t('odometer')}:
                                                {textBlock(`${item.odometer} km`)}
                                            </Text>

                                            {index !== 0 && <Text color="dimmed" size="sm">{t('transported_weight')}:
                                                {textBlock(item.cargo_weight)}
                                            </Text>}

                                            {item.cmr_nr && <>
                                                <Text color="dimmed"
                                                      size="sm">{t('cmr_nr')}: {textBlock(item.cmr_nr)}
                                                </Text>
                                            </>
                                            }
                                            {item.cmr_file && <>
                                                <Text color="dimmed"
                                                      size="sm">{t('cmr_file')}:
                                                    <Button size={'xs'}
                                                            compact
                                                            onClick={() => downloadFile(item)}
                                                            variant={'transparent'}>{t('download_file')}
                                                    </Button>
                                                </Text>
                                            </>
                                            }
                                        </Timeline.Item>)
                                    }
                                </Timeline>
                            </>
                        }
                    </div>
                </Grid.Col>


                <Grid.Col span={12}>
                    <div style={{marginTop: 10}} className={globalClasses.card}>
                        <div className={globalClasses.block_header}>
                            <Group align={'baseline'}>
                                <Title order={6} mt={10}>{t('spendings')}</Title>
                                {
                                    data.status === 'draft' && isNotSecondaryDriver() && !isShowing &&
                                    <Button mb={10} size={'xs'} disabled={loading} compact variant={'filled'}
                                            onClick={() => openNewSpending()}>
                                        {t('add_spending')}
                                    </Button>
                                }
                            </Group>
                        </div>
                        {
                            data.receipts && data.receipts.length > 0 &&
                            <div className={globalClasses.table_wrapper}
                                 style={{
                                     marginTop: 15,
                                     overflow: 'auto',
                                     maxHeight: 500,
                                     position: 'relative'
                                 }}>
                                <Table fontSize={'xs'} highlightOnHover>
                                    <thead style={{position: 'sticky', top: 0, background: 'inherit'}}>
                                    <tr>
                                        <th>{t('country')}</th>
                                        <th>{t('receipt_supplier')}</th>
                                        <th>{t('receipt_type')}</th>
                                        <th>{t('receipt_date')}</th>
                                        <th>{t('receipt_number')}</th>
                                        <th>{t('payment_type')}</th>
                                        <th>{t('receipt_amount')}</th>
                                        <th>{t('amount')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        data.receipts.map((item, index) => <tr
                                            key={`receipt_no_${index}`}>
                                            <td>{item.country ? countryOptions.find(ctr => ctr.value == item.country).label : ''}</td>
                                            <td style={{whiteSpace: 'nowrap'}}>{item.receipt_date}</td>
                                            <td>
                                                <strong>{item.receipt_supplier}                                                </strong>
                                            </td>
                                            <td>{item.receipt_type}</td>
                                            <td>{item.receipt_number}</td>
                                            <td>{item.payment_type ? t(`${item.payment_type}`) : ''}</td>
                                            <td>{`${item.receipt_amount} ${item.receipt_currency ? item.receipt_currency : ''}`}</td>
                                            <td>{item.amount}</td>
                                        </tr>)
                                    }
                                    </tbody>
                                </Table>
                            </div>
                        }
                    </div>
                </Grid.Col>
            </Grid>

            {
                data.status === 'draft' && isNotSecondaryDriver() && !isShowing &&
                <Button compact
                        disabled={loading}
                        size={'xs'}
                        mt={50}
                        mb={25}
                        onClick={() => openFinishTripSheet()}>{t('close_tripSheet')}</Button>
            }
        </div>
    </div>
}

export default TripSheetShow

const useStyles = createStyles((theme) => ({
    sheet: {
        // background: theme.colorScheme === 'dark' ? '#171717' : '#fff',
        width: '100%',
        position: 'relative',
    },
    trip_text: {
        color: theme.colorScheme === 'dark' ? '#171717' : '#fff',
    },
    block_header: {
        borderBottom: '1px solid #ddd',
        minHeight: 42
    }
}));



import {Badge, Grid, Table, TextInput, Button, LoadingOverlay, Title} from "@mantine/core";
import {Search} from "tabler-icons-react";
import {useEffect, useState} from "react";
import {getRolesPicker, getUsers} from "../../backend/axios";
import {closeModal, openModal} from "@mantine/modals";
// @ts-ignore
import moment from "moment";
import i18next from "i18next";
import {globalStyles} from "../../AppRouter";
import {useAppSettings} from "../../providers/AppProvider";
import UserForm from "../../forms/UserForm";

const UserList = () => {
    const {classes: globalClasses} = globalStyles()
    const {t} = i18next
    const [data, setData] = useState([])
    const [filter, setFilter] = useState({
        query: ''
    })
    const [loading, setLoading] = useState(false)

    const getData = (e = undefined) => {
        if (e) e.preventDefault()
        setLoading(true)
        getUsers().then(resp => {
            if (resp && resp.data) setData(resp.data)
            setLoading(false)
        }).catch(() => setLoading(false))
    }

    const {roles, setRoles} = useAppSettings();

    useEffect(() => {
        getData()

        if (roles.length === 0) {
            getRolesPicker().then(r => {
                if (r.data && Array.isArray(r.data)) setRoles(r.data)
            })
        }

    }, [])


    const openCreateCarModal = () => {
        openModal({
            modalId: 'userModal',
            title: t('new_user'),
            centered: true,
            overlayProps: {
                opacity: 0.2
            },
            children: <UserForm onCreate={onCreate} roleOptions={roles}/>
        })
    }

    const openUpdateCarModal = (item, index) => {
        openModal({
            modalId: 'userModal',
            title: t('update_user'),
            centered: true,
            overlayProps: {
                opacity: 0.2
            },
            children: <UserForm onSave={onSave} isUpdating={true} selected={{...item, listIndex: index}}
                                roleOptions={roles}/>
        })
    }

    const onSave = (item, index) => {
        let _cars = [...data]
        _cars[index] = item
        setData(_cars)
        closeModal('userModal')
    }

    const onCreate = (item) => {
        let _cars = [...data]
        _cars.unshift(item)
        setData(_cars)
        closeModal('userModal')
    }

    const filteredData = data.filter(obj => {
        const values = Object.values(obj)
        return values.some(value => {
            if (typeof value === 'string' || typeof value === 'number') {
                const regex = /[\u00C0-\u1FFF\u2C00-\uD7FF\w]+/g;
                const cleanValue = value.toString().toLowerCase().replace(regex, match => {
                    return match.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                });
                return cleanValue.includes(filter.query.toLowerCase());
            } else {
                return false
            }
        })
    })

    return <>
        <section className={'main'}>
            <div className={'main-content ' + globalClasses.card}>
                <div className={globalClasses.block_header}>
                    <Title order={6}>{t('page_users')}</Title>
                    <Button compact size={'xs'} variant={'filled'} onClick={() => openCreateCarModal()}>
                        {t('create_user')}
                    </Button>
                </div>


                <Grid align={'flex-end'} p={0} mt={15}>
                    <Grid.Col lg={8} md={8} sm={12}>
                        <TextInput placeholder={t('search')}
                                   variant={"unstyled"}
                                   styles={{root: {borderBottom: "1px solid #e8e9ec"}}}
                                   icon={<Search size={20}
                                                 strokeWidth={1.2}/>}
                                   value={filter.query}
                                   onChange={(e) => setFilter({...filter, query: e.target.value})}/>

                    </Grid.Col>
                </Grid>
                <div className={globalClasses.table_wrapper}>
                    <LoadingOverlay visible={loading} loaderProps={{size: 'sm'}} zIndex={5}/>
                    <Table fontSize={'xs'}
                           highlightOnHover>
                        <thead>
                        <tr>
                            <th>{t('user_name')}</th>
                            <th>{t('email')}</th>
                            <th>{t('phone')}</th>
                            <th>{t('time_card_id')}</th>
                            <th>{t('tachograph_card_id')}</th>
                            <th>{t('roles')}</th>
                            <th>{t('status')}</th>
                        </tr>
                        </thead>

                        {
                            data && data.length > 0 && <tbody>
                            {
                                filteredData.map((item, index) => <tr key={`user-${index}`}
                                                                      style={{cursor: 'pointer'}}
                                                                      onClick={() => openUpdateCarModal(item, index)}>

                                    <td>{item.display_name}</td>
                                    <td>{item.email}</td>
                                    <td>{item.phone}</td>
                                    <td>{item.time_card_id}</td>
                                    <td>{item.tachograph_card_id}</td>
                                    <td style={{fontSize: 12, color: '#646464', fontWeight: 500}}>
                                        {item.roles && item.roles.map((item, index) => <div key={`${index}-user`}>
                                            {item}
                                        </div>)}
                                    </td>
                                    <td>
                                        <Badge color={item.enabled ? 'green.8' : 'gray.5'}
                                               variant={item.enabled ? 'outline' : "filled"}
                                               styles={{root: {fontWeight: 600}}}>
                                            {t(item.status_text)}
                                        </Badge>
                                    </td>
                                </tr>)
                            }
                            </tbody>
                        }
                    </Table>
                </div>
            </div>
        </section>
    </>
}

export default UserList


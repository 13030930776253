import {createStyles} from '@mantine/core';
import {NavLink} from "react-router-dom";

const useStyles = createStyles((theme) => ({
    control: {
        fontSize: '11px',
        weight: 600,
        boxSizing: 'border-box',
        cursor: 'pointer',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        columnGap: 10,

        lineHeight: '1',
        margin: '0px',
        padding: '10px 5px',
        position: 'relative',
        textDecoration: 'none',
        width: '100%',
        fontFamily: '-apple-system, BlinkMacSystemFont, \'Segoe UI\', \'Roboto\', \'Oxygen\', \'Ubuntu\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\', sans-serif',
        borderLeft: '4px solid transparent',
        color: theme.colorScheme === 'dark' ? '#eaeaea' : '#42526e',
        fontWeight: 400,
        outlineStyle: 'none',
        'span': {
            marginTop: 7,
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },

        '&:hover': {
            color: theme.colorScheme === 'dark' ? '#fff' : '#3d4c65',
        },
    },
    active_control: {
        borderColor: '#a70535'
    }
}));

export function LinksGroup({icon: Icon, label, link}: any) {
    const {classes} = useStyles();

    return <NavLink
        to={link}
        className={({isActive}) =>
            isActive ? classes.control + ' ' + classes.active_control : classes.control
        }>
        <Icon size={23} style={{minWidth: 23, minHeight: 23}} strokeWidth={1.6}/>
        <div style={{wordBreak: 'break-word', textAlign: 'center'}}>{label}</div>
    </NavLink>
}

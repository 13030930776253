import {createStyles, Skeleton} from "@mantine/core";

const TableLoadingSkeleton = ({
                                  columnCount = 0,
                                  rowCount = 1,
                                  rowHeight = 25,
                                  rowWidth = '95%',
                                  rowMargin = 5,
                                  opacity = undefined
                              }) => {
    const {classes} = useStyles()

    const getCols = () => {
        let _cols = []
        for (let i = 0; i < columnCount; i++) {
            _cols.push(<td key={`col-${i}`}><Skeleton radius={2}
                                                      height={rowHeight}
                                                      width={rowWidth}
                                                      m={rowMargin}/></td>)
        }
        return _cols
    }

    const getRows = () => {
        let _rows = []
        let _col = getCols()
        for (let i = 0; i < rowCount; i++) {
            _rows.push(<tr key={`row-${i}`} className={classes.loading_tr}
                           style={{opacity: opacity ? opacity : 1.2 / (i + 0.05)}}>
                {_col}
            </tr>)
        }
        return _rows
    }

    return <>{columnCount && columnCount > 0 && rowCount && rowCount > 0 && getRows()}</>

}
export default TableLoadingSkeleton

const useStyles = createStyles((theme) => ({
    card: {
        padding: 20,

        background: theme.colorScheme === 'dark' ? '#171717' : '#fff',
        color: theme.colorScheme === 'dark' ? theme.colors.gray[4] : theme.colors.dark[7]
    },
    loading_tr: {
        'td': {
            padding: '0 !important'
        }
    }
}));

import {useSanctum} from "react-sanctum";
import {createSearchParams} from "react-router-dom";
import countryOptionsLT from '../backend/country_list.json'

const getErrorMessage = (errors, field) => {
    if (errors && errors[field])
        return <div>
            {errors[field].map((error, index) => <div key={index}>{error}</div>)}
        </div>
    else
        return undefined
}

const checkIfResponseHasErrors = (e) => {
    return !!(e && e.response && e.response.data && e.response.data.errors);
}

const getResponseErrors = (e, setLoading: any = undefined) => {
    if (setLoading) setLoading(false)
    if (e && e.response && e.response.data && e.response.data.errors) return e.response.data.errors
    else return {}
}

const createFileFormData = (file, name = 'payload') => {
    let formData = new FormData();
    formData.append(name, file);

    return formData;
}

const redirectWithSearch = (navigate, to, filter) => {
    navigate({
        pathname: to, search: createSearchParams({
            ...filter
        }).toString()
    })
}

const checkPermissions = (permissions = [], uUser: any = undefined) => {
    const {user: sanctumUser} = useSanctum()

    let user = uUser && uUser.id ? uUser : sanctumUser

    if (Array.isArray(permissions) && permissions.length > 0) return !!(user && ((user.permissions && permissions.some(prm => user.permissions.includes(prm))) || (user.roles && user.roles.includes('Super Admin'))));
    else return false
}

const findObjectById = (obj, id) => {
    if (obj && id) {
        if (obj.id === id) {
            return obj;
        }
        for (let i in obj) {
            if (obj[i] !== null && typeof obj[i] === 'object') {
                let result = findObjectById(obj[i], id);
                if (result) {
                    return result;
                }
            }
        }
    }
    return null;
}

const getCountryName = (code) => {
    let lang = getSetLang(false)
    if (lang == 'lt') {
        return countryOptionsLT.find(ctr => ctr.value == code).label
    }
    if (lang == 'ru') {
        return countryOptionsLT.find(ctr => ctr.value == code).label
    }
}


const getSetLang = (setLang = true) => {
    if (!localStorage.getItem('gvr-lang')) {
        if (setLang) localStorage.setItem('gvr-lang', 'lt')
        return 'lt'
    } else return localStorage.getItem('gvr-lang')
}

const setLang = (lng) => {
    localStorage.setItem('gvr-lang', lng)
    window.location.reload()
}


export {
    setLang,
    getSetLang,
    getErrorMessage,
    checkIfResponseHasErrors,
    getResponseErrors,
    checkPermissions,
    redirectWithSearch,
    findObjectById,
    createFileFormData
}

import {useEffect, useState} from "react";

export const useBaseObjectForm = (preDefinedValues: any = undefined) => {

    const [data, setData] = useState(preDefinedValues ? preDefinedValues : {})
    const [errors, setErrors] = useState({})
    const [isChanged, setIsChanged] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setIsChanged(data && preDefinedValues && JSON.stringify(data) !== JSON.stringify(preDefinedValues))
    }, [data, preDefinedValues])

    const onDataChange = (values) => {
        let _errors = {...errors}
        Object.keys(values).map(key => {
            delete _errors[key]
        })
        setErrors(_errors)

        setData({...data, ...values})
    }

    return {data, setData, errors, setErrors, onDataChange, isChanged, setIsChanged, loading, setLoading}
}

export const useBaseAxiosCancel = () => {

    const [axiosToken, setAxiosToken]: any = useState();
    const [searchTimeout, setSearchTimeout]: any = useState();

    return {axiosToken, setAxiosToken, searchTimeout, setSearchTimeout}
}

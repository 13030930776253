import {Button, Grid, LoadingOverlay, Table, TextInput, Title} from "@mantine/core";
import {Search} from "tabler-icons-react";
import {useEffect, useState} from "react";
import TableLoadingSkeleton from "../../components/TableLoadingSkeleton";
import {getCars} from "../../backend/axios";
import {closeModal, openModal} from "@mantine/modals";
// @ts-ignore
import moment from "moment";
import i18next from "i18next";
import {globalStyles} from "../../AppRouter";
import NewCarForm from "../../forms/CarForm";

const CarList = () => {
    const {classes: globalClasses} = globalStyles()
    const {t} = i18next
    const [data, setData] = useState([])
    const [filter, setFilter] = useState({
        query: ''
    })
    const [loading, setLoading] = useState(false)

    const getData = (e: any = undefined) => {
        if (e) e.preventDefault()
        setLoading(true)
        getCars().then(resp => {
            if (resp && resp.data) setData(resp.data)
            setLoading(false)
        }).catch(() => setLoading(false))
    }

    useEffect(() => {
        getData()
    }, [])

    const openCreateCarModal = () => {
        openModal({
            modalId: 'carModal',
            title: t('new_car'),
            centered: true,
            overlayProps: {
                opacity: 0.2
            },
            children: <NewCarForm onCreate={onCreate}/>
        })
    }

    const openUpdateCarModal = (item, index) => {
        openModal({
            modalId: 'carModal',
            title: t('update_car'),
            centered: true,
            overlayProps: {
                opacity: 0.2
            },
            children: <NewCarForm onSave={onSave} isUpdating={true} selected={{...item, listIndex: index}}/>
        })
    }

    const onSave = (item, index) => {
        let _cars: any = [...data]
        _cars[index] = item
        setData(_cars)
        closeModal('carModal')
    }

    const onCreate = (item) => {
        let _cars: any = [...data]
        _cars.unshift(item)
        setData(_cars)
        closeModal('carModal')
    }

    return <>
        <section className={'main'}>
            <div className={'main-content ' + globalClasses.card}>
                <div className={globalClasses.block_header}>
                    <Title order={6}>{t('page_cars')}</Title>
                    <Button compact size={'xs'} variant={'default'} onClick={() => openCreateCarModal()}>
                        {t('add_car')}
                    </Button>
                </div>

                <Grid align={'flex-end'} p={0} mt={15}>
                    <Grid.Col lg={8} md={8} sm={12}>
                        <TextInput placeholder={t('search') || ''}
                                   variant={"unstyled"}
                                   styles={{root: {borderBottom: "1px solid #e8e9ec"}}}
                                   icon={<Search size={20}
                                                 strokeWidth={1.2}/>}
                                   value={filter.query}
                                   onChange={(e) => setFilter({...filter, query: e.target.value})}/>

                    </Grid.Col>
                </Grid>
                <div className={globalClasses.table_wrapper}>
                    <LoadingOverlay visible={loading} loaderProps={{size: 'sm'}} zIndex={5}/>
                    <Table fontSize={'xs'} highlightOnHover className={'productListTable'}
                           style={{minWidth: 500}}>
                        <thead>
                        <tr>
                            <th>{t('make')}</th>
                            <th>{t('model')}</th>
                            <th>{t('plate')}</th>
                            <th>{t('vin')}</th>
                            <th>{t('manufacture_date')}</th>
                            <th>{t('odometer')}</th>
                            <th>{t('tank_capacity')}</th>
                        </tr>
                        </thead>

                        <tbody>
                        {loading ? <TableLoadingSkeleton columnCount={6} rowCount={4}/>
                            : data && data.length > 0 && data.map((item: any, index) => <tr key={`user-${index}`}
                                                                                            style={{cursor: 'pointer'}}
                                                                                            onClick={() => openUpdateCarModal(item, index)}>

                            <td>{item.make}</td>
                            <td>{item.model}</td>
                            <td>{item.plate}</td>
                            <td>{item.vin}</td>
                            <td style={{whiteSpace: "nowrap"}}>{item.manufacture_date}</td>
                            <td>{item.odometer}</td>
                            <td>{item.tank_capacity}</td>
                        </tr>)
                        }
                        </tbody>
                    </Table>
                </div>
            </div>
        </section>
    </>
}

export default CarList




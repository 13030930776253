import {getErrorMessage} from "../../helpers/helpers";
import {createStyles, PasswordInput, TextInput, Button, Checkbox} from "@mantine/core";
import {useBaseObjectForm} from "../../customHooks/hooks";
import {useSanctum} from "react-sanctum";
import {useEffect, useRef, useState} from "react";
// @ts-ignore
import logo_sm from '../../../images/gevara_dark.png'
import i18next from "i18next";

const Login = () => {
    const {classes} = useStyles()
    const [loading, setLoading] = useState(false)
    const {data, errors, setErrors, onDataChange}: any = useBaseObjectForm({
        email: '',
        password: '',
        remember: false
    })
    const {signIn} = useSanctum();

    const passwordInputRef: any = useRef();
    const emailInputRef: any = useRef();

    const [ffr, sffr] = useState(null)//forced rerender

    const submitLogin = (e) => {
        e.preventDefault()
        setLoading(true)
        signIn(data.email, data.password, data.remember)
            .then(() => {
                setLoading(false)
            })
            .catch(r => {
                passwordInputRef.current.focus();
                if (r.response.status === 400) {
                    setErrors(r.response.data.errors)
                    sffr(!ffr);
                }
                setLoading(false)
            })
    }

    useEffect(() => {
        if (ffr !== null) passwordInputRef.current.focus()
        else emailInputRef.current.focus()
    }, [ffr])

    return <div className={classes.form_wrapper}>
        <div className={classes.header}>
            {
                logo_sm && <div>
                    <img src={logo_sm} width={160} alt={'gevara'}/>
                </div>
            }
        </div>

        <div style={{padding: '24px 32px'}}>
            <form onSubmit={submitLogin}>
                <TextInput label={i18next.t('login_name')}
                           disabled={loading}
                           error={getErrorMessage(errors, 'email')}
                           value={data.email}
                           ref={emailInputRef}
                           onChange={(e) => onDataChange({email: e.target.value})}/>

                <PasswordInput label={i18next.t('password')}
                               disabled={loading}
                               error={getErrorMessage(errors, 'password')}
                               value={data.password}
                               ref={passwordInputRef}
                               onChange={(e) => onDataChange({password: e.target.value})}/>

                <Checkbox style={{marginTop: 10}} label={i18next.t('remember_me')}
                          checked={data.remember} onChange={() => onDataChange({remember: !data.remember})}/>


                <div className={classes.button_placement}>
                    <Button className={classes.button} compact type={'submit'} loading={loading}><span
                        style={{padding: '0 15px'}}>{i18next.t('login')}</span></Button>
                </div>
            </form>
        </div>
    </div>
}

export default Login

const useStyles = createStyles((theme) => ({
    button: {
        fontSize: '.875rem',
        fontWeight: 600,
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0.3rem',
        background: '#010101',
        '.heading__text': {
            color: '#1A0326',
            padding: '1rem 0 1.2rem',
            fontSize: '1.875rem',
            fontWeight: 500,
        }
    },
    muted_link: {
        fontSize: '0.75rem',
        color: theme.colors.gray[6]
    },
    form_wrapper: {
        margin: 'auto',
        boxShadow: '4px 4px 16px 0 rgb(0 0 0 / 30%)',
        backgroundColor: theme.colorScheme === 'dark' ? '#000000' : theme.white,
        maxWidth: 450,
        width: '100%',
        borderRadius: 4,
        height: 'max-content',
        transition: 'max-height 0.5s',
    },
    button_placement: {
        marginTop: 20,
        textAlign: 'center'
    }
}));

import i18next from "i18next";
import {useBaseObjectForm} from "../../customHooks/hooks";
import {useState} from "react";
import moment from "moment";
import {createTripForTripSheet} from "../../backend/axios";
import {getErrorMessage, getResponseErrors} from "../../helpers/helpers";
import {Button, Divider, Group, LoadingOverlay, Select, TextInput, Text, useMantineTheme, Input} from "@mantine/core";
import {DatePickerInput} from "@mantine/dates";
import countryOptions from "../../backend/country_list.json";
import {Dropzone} from "@mantine/dropzone";
import {File, Photo, Upload, X} from "tabler-icons-react";

const TripModal = ({id = undefined, onSave = undefined, isCreating = false, car = undefined}: any) => {
    const {t} = i18next
    const {data, onDataChange, setErrors, errors} = useBaseObjectForm({
        trip_stop_at: new Date(),
        trip_stop_country: isCreating ? 'LT' : 'LT',
        trip_stop_location: '100',
        odometer: car && car.id && car.odometer ? car.odometer : '100',
        cargo_weight: '100',
        cmr_nr: '100',
        cmr_file: '100'
    })
    const theme = useMantineTheme()

    const [loading, setLoading] = useState(false)
    const onSubmit = (e) => {
        if (e) e.preventDefault()
        setLoading(true)

        let _trip_stop_at = ''
        if (data.trip_stop_at) _trip_stop_at = moment(data.trip_stop_at).format('YYYY-MM-DD')

        let formData = new FormData();

        Object.keys(data).map(key => {
            if (key == 'trip_stop_at') formData.append(key, _trip_stop_at)
            else formData.append(key, Array.isArray(data[key]) ? JSON.stringify(data[key]) : data[key])
        })

        if (id) createTripForTripSheet(id, formData).then(r => {
            if (r && r.data && r.data && onSave) onSave(r.data)
            setLoading(false)
        }).catch(e => {
            setErrors(getResponseErrors(e))
            setLoading(false)
        })
    }

    return <>
        <LoadingOverlay visible={loading}/>
        <form onSubmit={onSubmit}>
            <DatePickerInput label={t('date')}
                             valueFormat={'YYYY-MM-DD'}
                             defaultLevel={'year'}
                             firstDayOfWeek={1}
                             value={data.trip_stop_at || null}
                             error={getErrorMessage(errors, 'trip_stop_at')}
                             onChange={(dt) => onDataChange({trip_stop_at: dt})}/>

            <Divider mt={20} mb={10}/>

            <Select label={isCreating ? t('trip_start_country') : t('trip_stop_country')}
                    data={countryOptions}
                    searchable
                    withinPortal
                    value={data.trip_stop_country}
                    error={getErrorMessage(errors, 'trip_stop_country')}
                    onChange={(itm) => onDataChange({trip_stop_country: itm})}/>

            <TextInput label={isCreating ? t('trip_start_location') : t('trip_stop_location')}
                       value={data.trip_stop_location}
                       error={getErrorMessage(errors, 'trip_stop_location')}
                       onChange={(e) => onDataChange({trip_stop_location: e.target.value})}/>

            <Divider mt={20} mb={10}/>

            <TextInput label={t('trip_stop_odometer')}
                       value={data.odometer}
                       error={getErrorMessage(errors, 'odometer')}
                       onChange={(e) => onDataChange({odometer: e.target.value})}/>

            {
                !isCreating && <>
                    <TextInput label={t('trip_stop_weight')}
                               value={data.cargo_weight}
                               error={getErrorMessage(errors, 'cargo_weight')}
                               onChange={(e) => onDataChange({cargo_weight: e.target.value})}/>

                    <Divider mt={15} m={5}/>

                    <TextInput label={t('cmr_nr')}
                               value={data.cmr_nr}
                               error={getErrorMessage(errors, 'cmr_nr')}
                               onChange={(e) => onDataChange({cmr_nr: e.target.value})}/>

                    <Input.Wrapper label={t('cmr_file')} error={getErrorMessage(errors, 'cmr_file')} mt={10}>
                        <Dropzone onDrop={(files) => onDataChange({cmr_file: files[0]})}>
                            <Group position="center" spacing="xl" style={{minHeight: '100px', pointerEvents: 'none'}}>
                                {
                                    data.cmr_file ? <div>
                                            <Group mt={10} align={'center'} position={'center'}>
                                                <File size={22} strokeWidth={1.6}/>
                                                <Text size={'lg'}>{data.cmr_file.name}</Text>
                                            </Group>
                                        </div>
                                        : <><Dropzone.Accept>
                                            <Upload
                                                size="2em"
                                                strokeWidth={1.5}
                                                color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                            />
                                        </Dropzone.Accept>
                                            <Dropzone.Reject>
                                                <X
                                                    size="2em"
                                                    strokeWidth={1.5}
                                                    color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                                />
                                            </Dropzone.Reject>
                                            <Dropzone.Idle>
                                                <Group>
                                                    <Photo size="2em" strokeWidth={1.5}/>
                                                    <Text size="lg" inline>
                                                        {` / `}
                                                    </Text>
                                                    <File size="2em" strokeWidth={1.5}/>
                                                </Group>
                                            </Dropzone.Idle>

                                            <div>
                                                <Text size="md" inline>
                                                    {t('cmr_file_upload_text')}
                                                </Text>
                                            </div>
                                        </>
                                }
                            </Group>
                        </Dropzone>
                    </Input.Wrapper>
                </>
            }

            <Button compact mt={20} type={"submit"}>{t('save')}</Button>
        </form>

    </>
}

export default TripModal

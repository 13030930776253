import {Modal, PasswordInput, TextInput, Button, Title, Text, Grid, Menu} from "@mantine/core";
import {useSanctum} from "react-sanctum";
import {useEffect, useState} from "react";
import {useBaseObjectForm} from "../customHooks/hooks";
import {getErrorMessage, getResponseErrors} from "../helpers/helpers";
import {updateUserPassword, updateUserProfile} from "../backend/axios";
import {showNotification} from "@mantine/notifications";
import i18next from "i18next";
import {Logout, User} from "tabler-icons-react";
import {useNavigate} from "react-router-dom";

export const ProfileButton = () => {
    const {signOut} = useSanctum()
    const {t} = i18next
    const navigate= useNavigate()
    const {user, setUser} = useSanctum()
    const [openProfileModal, setOpenProfileModal] = useState(false)
    const {data, setData: setUserData, setErrors, errors, onDataChange, loading, setLoading}: any = useBaseObjectForm({
        id: '',
        first_name: '',
        last_name: '',
        email: '',
        ...user
    })

    const {
        data: passData,
        setData: setPassData,
        setErrors: setPassErrors,
        errors: passErrors,
        onDataChange: onPassChange,
        loading: passLoading,
        setLoading: setPassLoading
    }: any = useBaseObjectForm({
        old_password: '',
        password: '',
        password_confirmation: ''
    })

    useEffect(() => {
        if (user && user.id) setUserData(user)
    }, [user])

    const onSignOutPress = () => {
        signOut().then(() => navigate('/login'))
    }

    const saveProfile = (e) => {
        if (e) e.preventDefault()
        setLoading(true)
        updateUserProfile(data)
            .then(r => {
                if (r && r.status === 200) {
                    showNotification({color: 'green', message: t('profile_updated') || 'Profile updated'})
                    setUser(r.data)
                }
                setLoading(false)
            })
            .catch(e => {
                setErrors(getResponseErrors(e))
                setLoading(false)
            })
    }

    const savePassword = (e) => {
        if (e) e.preventDefault()
        setPassLoading(true)
        updateUserPassword(passData)
            .then(r => {
                if (r && r.status === 200) {
                    showNotification({color: 'green', message: t('password_updated') || 'Password changed'})
                    setPassData({password: '', old_password: '', password_confirmation: ''})
                }
                setPassLoading(false)
            })
            .catch(e => {
                setPassErrors(getResponseErrors(e))
                setPassLoading(false)
            })
    }

    return <>
        {
            data && data.id && <>

                <Menu>
                    <Menu.Target>
                        <Button size={'sm'} compact radius={8}
                                variant={'transparent'}>{`${data.first_name ? data.first_name : ''} ${data.last_name ? data.last_name : ''}`}</Button>
                    </Menu.Target>
                    <Menu.Dropdown>

                        <Menu.Item p={6} onClick={() => setOpenProfileModal(true)} icon={<User size={14}/>}>
                            <Text size={'xs'}>Manage account</Text>
                        </Menu.Item>

                        <Menu.Divider/>

                        <Menu.Item onClick={() => onSignOutPress()} color="red.6" p={8} icon={<Logout size={14}/>}>
                            <Text size={'xs'}>Logout</Text></Menu.Item>
                    </Menu.Dropdown>
                </Menu>

                <Modal opened={openProfileModal}
                       size={'100%'}
                       onClose={() => setOpenProfileModal(false)}
                       centered={true}

                       title={<div style={{display: 'flex', gap: 5, alignItems: 'center'}}>
                           <span>
                               {`${data.first_name ?? data.first_name} ${data.last_name ?? data.last_name}`}
                           </span>
                       </div>}

                       styles={{
                           content: {
                               overflow: 'visible !important',
                               maxWidth: '750px !important'
                           }
                       }}>
                    <Grid style={{width: '100%'}}>
                        <Grid.Col lg={6} md={6} sm={12}>
                            <Title order={4}>Vartotojas</Title>
                            <form onSubmit={saveProfile}>
                                <div>
                                    <TextInput label={t('first_name') || 'First name'}
                                               disabled={passLoading || loading}
                                               value={data.first_name}
                                               onChange={(e) => onDataChange({first_name: e.target.value})}
                                               error={getErrorMessage(errors, 'first_name')}/>

                                    <TextInput label={t('last_name') || 'Last name'}
                                               disabled={passLoading || loading}
                                               value={data.last_name}
                                               onChange={(e) => onDataChange({last_name: e.target.value})}
                                               error={getErrorMessage(errors, 'last_name')}/>

                                    <TextInput label={t('email') || 'Email'}
                                               disabled={passLoading || loading}
                                               value={data.email}
                                               onChange={(e) => onDataChange({email: e.target.value})}
                                               error={getErrorMessage(errors, 'email')}/>
                                </div>
                                <Button size={"xs"} mt={15} compact type={'submit'}
                                        loading={passLoading || loading}>{t('save_changes')}</Button>
                            </form>
                        </Grid.Col>

                        <Grid.Col lg={6} md={6} sm={12}>
                            <Title order={4}>Keisti slaptažodį</Title>
                            <form onSubmit={savePassword}>
                                <div>
                                    <PasswordInput label={t('old_password') || 'Old password'}
                                                   disabled={passLoading || loading}
                                                   value={passData.old_password}
                                                   onChange={(e) => onPassChange({old_password: e.target.value})}
                                                   error={getErrorMessage(passErrors, 'old_password')}/>

                                    <PasswordInput label={t('new_password') || 'New password'}
                                                   disabled={passLoading || loading}
                                                   value={passData.password}
                                                   onChange={(e) => onPassChange({password: e.target.value})}
                                                   error={getErrorMessage(passErrors, 'password')}/>

                                    <PasswordInput label={t('confirm_new_password') || 'Confirm new password'}
                                                   disabled={passLoading || loading}
                                                   value={passData.password_confirmation}
                                                   onChange={(e) => onPassChange({password_confirmation: e.target.value})}
                                                   error={getErrorMessage(passErrors, 'password_confirmation')}/>
                                </div>
                                <Button size={"xs"} mt={15} compact type={'submit'} loading={passLoading || loading}>
                                    {t('save_changes')}
                                </Button>
                            </form>
                        </Grid.Col>
                    </Grid>
                </Modal>
            </>
        }
    </>
}

import i18next from "i18next";
import {useNavigate} from "react-router-dom";
import {useBaseObjectForm} from "../../customHooks/hooks";
import {useState} from "react";
import moment from "moment";
import {updateTripSheet} from "../../backend/axios";
import {closeAllModals, closeModal} from "@mantine/modals";
import {getErrorMessage, getResponseErrors} from "../../helpers/helpers";
import {DatePickerInput} from "@mantine/dates";
import {Calendar} from "tabler-icons-react";
import {Button, TextInput} from "@mantine/core";

const CloseTripSheetModal = (tripSheetData = undefined, setData) => {
    const {t} = i18next
    const navigate = useNavigate()
    const {data, onDataChange, setErrors, errors} = useBaseObjectForm({
        trip_sheet_end_at: new Date()
    })

    const [loading, setLoading] = useState(false)

    const onCloseTripSheet = (e) => {
        if (e) e.preventDefault()

        let _data = {...tripSheetData.tripSheetData, ...data}
        setLoading(true)
        let _trip_sheet_start_at = ''
        let _trip_sheet_end_at = ''
        if (_data.trip_sheet_start_at) _trip_sheet_start_at = moment(_data.trip_sheet_start_at).format('YYYY-MM-DD')
        if (_data.trip_sheet_end_at) _trip_sheet_end_at = moment(_data.trip_sheet_end_at).format('YYYY-MM-DD')

        updateTripSheet(_data.id, {
            ..._data,
            close_trip_sheet: true,
            trip_sheet_start_at: _trip_sheet_start_at,
            trip_sheet_end_at: _trip_sheet_end_at
        })
            .then((r) => {
                navigate(`/my-travels/${_data.id}`)
                closeModal('finishTripSheetModal')
                closeAllModals()
                if (r && r.data && r.data.id && setData) setData(r.data)
                setLoading(false)
            })
            .catch(e => {
                setErrors(getResponseErrors(e))
                setLoading(false)
            })
    }

    return <form onSubmit={onCloseTripSheet}>
        <DatePickerInput label={'Kelionės lapo pabaiga'}
                         style={{flex: '1'}}
                         defaultLevel={'year'}
                         valueFormat={'YYYY-MM-DD'}
                         required
                         firstDayOfWeek={1}
                         icon={<Calendar size={16}/>}
                         error={getErrorMessage(errors, 'trip_sheet_end_at')}
                         value={data.trip_sheet_end_at ? new Date(data.trip_sheet_end_at) : null}
                         onChange={(dt) => onDataChange({trip_sheet_end_at: dt})}/>

        {
            data.moto_hours_start && <TextInput label={'Moto val. pabaiga'}
                                                value={data.moto_hours_end || ''}
                                                onChange={(e) => onDataChange({moto_hours_end: e.target.value})}
                                                error={getErrorMessage(errors, 'moto_hours_end')}
                                                style={{flex: '1'}}/>
        }

        <TextInput label={'Kuro likutis'}
                   value={data.fuel_end || ''}
                   required
                   onChange={(e) => onDataChange({fuel_end: e.target.value})}
                   error={getErrorMessage(errors, 'fuel_end')}
                   style={{flex: '1'}}/>


        <div style={{marginTop: 20, display: 'flex', gap: 15, justifyContent: 'center'}}>
            <Button compact disabled={loading} type={'submit'}>
                {t('close_tripSheet')}
            </Button>
        </div>
    </form>
}

export default CloseTripSheetModal

import i18next from "i18next";
import {useBaseObjectForm} from "../customHooks/hooks";
import {useAppSettings} from "../providers/AppProvider";
import {useState} from "react";
import moment from "moment";
import {storeTrailer, updateTrailer} from "../backend/axios";
import {showNotification} from "@mantine/notifications";
import {closeModal} from "@mantine/modals";
import {getErrorMessage, getResponseErrors} from "../helpers/helpers";
import {Button, Checkbox, Input, LoadingOverlay, Select, TextInput} from "@mantine/core";
import {DatePickerInput} from "@mantine/dates";

const TrailerForm = (
    {
        onCreate = undefined, onSave = undefined, isUpdating = false, selected = undefined
    }
        : any) => {
    const {t} = i18next
    const {data, onDataChange, setErrors, errors} = useBaseObjectForm({
        plate: '',
        type: '',
        manufacturer: '',
        manufacture_date: null,
        vin: '',
        moto_hours_required: false,
        ...selected
    })

    const {appSettings} = useAppSettings()

    const trailerTypes = appSettings.trailer_types.map(type => {return {label: t(type), value: type}})

    const [loading, setLoading] = useState(false)
    const onSubmit = (e) => {
        if (e) e.preventDefault()
        setLoading(true)

        let _manufacture_date = ''
        if (data.manufacture_date) _manufacture_date = moment(data.manufacture_date).format('YYYY-MM-DD')

        if (isUpdating) updateTrailer(selected.id, {...data, manufacture_date: _manufacture_date}).then(r => {
            if (r && r.data && onSave) {
                onSave(r.data, selected.listIndex)
                showNotification({color: 'green', message: t('trailer_updated')})
                closeModal('trailerModal')
            }
            setLoading(false)
        }).catch(e => setErrors(getResponseErrors(e, setLoading)))

        else storeTrailer({...data, manufacture_date: _manufacture_date}).then(r => {
            if (r && r.data && onCreate) {
                showNotification({color: 'green', message: t('trailer_created')})
                onCreate(r.data)
                closeModal('trailerModal')
            }
            setLoading(false)
        }).catch(e => setErrors(getResponseErrors(e, setLoading)))
    }

    return <>
        <div style={{position: 'relative'}}>
            <LoadingOverlay visible={loading}/>
            <form onSubmit={onSubmit}>
                <TextInput label={t('manufacturer')}
                           value={data.manufacturer}
                           error={getErrorMessage(errors, 'manufacturer')}
                           onChange={(e) => onDataChange({manufacturer: e.target.value})}/>

                <DatePickerInput label={t('manufacture_date')}
                                 value={data.manufacture_date ? new Date(data.manufacture_date) : null}
                                 defaultLevel={'year'}
                                 valueFormat={'YYYY-MM-DD'}
                                 firstDayOfWeek={1}
                                 error={getErrorMessage(errors, 'manufacture_date')}
                                 onChange={(dt) => onDataChange({manufacture_date: dt})}/>

                <TextInput label={t('plate')}
                           value={data.plate}
                           placeholder={'XXX 000'}
                           error={getErrorMessage(errors, 'plate')}
                           onChange={(e) => onDataChange({plate: e.target.value})}/>

                <TextInput label={t('vin')}
                           value={data.vin}
                           error={getErrorMessage(errors, 'vin')}
                           onChange={(e) => onDataChange({vin: e.target.value})}/>

                <Select
                    data={trailerTypes}
                    value={data.type}
                    label={t('type')}
                    onChange={(itm) => onDataChange({type: itm})}
                    error={getErrorMessage(errors, 'type')}/>

                <Input.Wrapper error={getErrorMessage(errors, 'moto_hours_required')}>
                    <Checkbox label={t('moto_hours_required') || 'Reikalingos moto valandos'} mt={15}
                              checked={data.moto_hours_required}
                              onChange={(e) => onDataChange({moto_hours_required: e.target.checked})}/>
                </Input.Wrapper>

                <Button compact mt={20} type={"submit"}>{t('save')}</Button>
            </form>
        </div>
    </>
}

export default TrailerForm
